import React from 'react';
import type { IProps } from './type';
import Modal from '../../../../shared/design-system/components/atoms/modal';
import { ChevronLeft, Copy, GoogleNew } from '@saleshandy/icons';
import { useTranslation } from 'react-i18next';
import toaster, { Theme } from '../../../../shared/toaster';
import ImageIcon from '../../../../shared/components/images/image-icon';
import { Images } from '../../../../shared/app-constants';
import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import VideoModal from '../../../../shared/components/video-modal';

const ConnectGoogleAccountsModal: React.FC<IProps> = ({
  show,
  onClose,
  onBack,
  onSubmit,
  isSubmitLoading = false,
  isSubmitDisabled = false,
}) => {
  const { t } = useTranslation();
  const [isVideoModalVisible, setIsVideoModalVisible] = React.useState<boolean>(
    false,
  );

  const clientId = process.env.REACT_APP_GMAIL_OAUTH_CLIENT_ID;

  const copyClientIdToClipboard = () => {
    if (clientId) {
      navigator.clipboard.writeText(clientId).then(() => {
        toaster.success('Client ID copied Successfully', {
          theme: Theme.New,
        });
      });
    }
  };

  const getBulletViewForGmail = () => (
    <div className="steps-section">
      <div className="steps-section-points">
        <ol className="regular-1 gray-txt-15">
          <li>
            {t('messages.step_one')}{' '}
            <a
              href="https://admin.google.com/u/3/ac/owl/list?tab=configuredApps"
              target="_blank"
              rel="noreferrer"
              className="a-link font-semibold"
            >
              {t('messages.step_one_a')}
            </a>
          </li>
          <li>
            {t('messages.step_two')}{' '}
            <span className="font-semibold gray-txt-12">
              {' '}
              {t('messages.step_two_a')}
            </span>
          </li>
          <li>
            {t('messages.step_three')}
            <span className="font-semibold gray-txt-12">
              {' '}
              {t('messages.step_three_a')}
            </span>
            <span> {t('messages.step_three_b')}</span>
          </li>
          <li>{t('messages.step_four')}</li>
        </ol>
        <div className="client-id-container">
          <span>{clientId}</span>
        </div>
        <div
          className="copy-client-id-container pointer"
          onClick={copyClientIdToClipboard}
        >
          <Copy className="copy-icon" />
          <span className="label">Copy Client Id </span>
        </div>
        <ol start={5} className="regular-1 gray-txt-15">
          <li className="regular-1 gray-txt-15">
            {t('messages.step_five')}
            <span className="font-semibold gray-txt-12">
              {' '}
              {t('messages.step_five_a')}
            </span>
            <span> {t('messages.step_five_b')}</span>
          </li>
        </ol>
      </div>
    </div>
  );

  return (
    <Modal
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitLoading={isSubmitLoading}
      isSubmitDisabled={isSubmitDisabled}
      titleContent={
        <div>
          <ChevronLeft className="left-icon pointer" onClick={onBack} />
          <span className="icon-modal-text title">
            Connect your Google Account
          </span>
          <GoogleNew className="title" />
        </div>
      }
      showCloseIcon={true}
      submitButtonText="Connect Account"
      hideCancelButton={true}
      backdrop="static"
      className="connect-google-accounts-modal"
    >
      <div className="d-flex flex-column steps-container">
        <span className="sub-title">Steps to connect your google account</span>
        <div>
          <span className="sub-title-2">
            Google OAuth with Trulyinbox in Just 1 Click!{' '}
            <span className="icon-container">
              <OverlayTooltip
                text="Learn how to connect google account"
                placement={Placement.Bottom}
              >
                <ImageIcon
                  onClick={() => setIsVideoModalVisible(true)}
                  src={Images.PlayCircle}
                  className="icon-play"
                />
              </OverlayTooltip>
            </span>
          </span>
          <br />
          <span className="d-flex sub-title-3">
            This is one time setup for each domain.
          </span>
        </div>
      </div>
      {getBulletViewForGmail()}
      <VideoModal
        show={isVideoModalVisible}
        onClose={() => setIsVideoModalVisible(false)}
      />
    </Modal>
  );
};

export default ConnectGoogleAccountsModal;

import React, { useEffect, useRef } from 'react';
import { supportUrls } from '../../utils/urls';
import Modal from '../../design-system/components/atoms/modal';

type IProps = {
  show: boolean;
  onClose: VoidFunction;
};

const VideoModal: React.FC<IProps> = ({ show, onClose }) => {
  const viewSampleSequenceBtnRef = useRef<HTMLButtonElement>();

  const getVideoURL = () => {
    return supportUrls.goauthStepsToConnectVideoURL;
  };

  useEffect(() => {
    if (show && viewSampleSequenceBtnRef.current) {
      viewSampleSequenceBtnRef.current.focus();
    }
  }, [viewSampleSequenceBtnRef, show]);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://fast.wistia.net/assets/external/E-v1.js',
    );
    script.setAttribute('async', '');

    document.body.appendChild(script);
  }, []);

  return (
    <Modal
      show={show}
      showCloseIcon
      onCancel={onClose}
      onClose={onClose}
      onHide={onClose}
      className="video-modal"
      backdrop={true}
      hideHeader
      hideFooter
    >
      <div className="watch-video-modal-body">
        <div className="d-flex justify-content-center align-items-center">
          <iframe
            src={getVideoURL()}
            title="Final welcome video (1)"
            allow="autoplay; fullscreen"
            allowTransparency={true}
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            style={{
              border: 'none',
              width: '100%',
              height: '24rem',
              maxHeight: '700px',
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;

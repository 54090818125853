/* eslint-disable @typescript-eslint/no-shadow */
import { map } from 'lodash';
import { FilterProperties } from '../../../shared/types/prospects-filters';
import {
  EmailAccountsFilters,
  EmailAccountStatus,
  EmailAccountStatusTypeDisplayName,
  EmailAccountTags,
  EmailAccountTagsTyeDisplayName,
} from '../enums';
import { EmailAccountFilters, EmailAccountWarmupFilters } from '../types';
import { EmailWarmupDatePickerOption } from '../../reports/components/reports-content/components/reports-filters/components/date-range-picker/types';
import moment from 'moment-timezone';

// To separate filters when displayed on UI
export const filterSeparator = ', ';

export const filterToProperties: Record<
  EmailAccountsFilters,
  FilterProperties
> = {
  tags: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  status: {
    displayNameKey: 'name',
    valueKey: 'id',
    separator: filterSeparator,
  },
  'created date': {
    displayNameKey: 'displayDate',
    valueKey: 'date',
    separator: ' - ',
  },
};

/**
 * Prospect Statuses
 */
export const tags = [
  {
    name: EmailAccountTagsTyeDisplayName.None,
    id: EmailAccountTags.None,
  },
];

export const status = [
  {
    name: EmailAccountStatusTypeDisplayName.Active,
    id: EmailAccountStatus.Active,
  },
  {
    name: EmailAccountStatusTypeDisplayName.Stop,
    id: EmailAccountStatus.Stop,
  },
  {
    name: EmailAccountStatusTypeDisplayName.Pause,
    id: EmailAccountStatus.Pause,
  },
  {
    name: EmailAccountStatusTypeDisplayName.Blocked,
    id: EmailAccountStatus.Blocked,
  },
];

export enum EmailWarmupStartedAtFilters {
  Today = 'today',
  Last7Days = 'last_7_days',
  Last14Days = 'last_14_days',
  Last30Days = 'last_30_days',
  MoreThan30Days = 'more_than_30_days',
}

export const getWarmupStartedAtFilterValues = (
  filterOption: EmailWarmupStartedAtFilters,
) => {
  let startDate: string = null;
  let endDate: string = null;
  switch (filterOption) {
    case EmailWarmupStartedAtFilters.Today:
      startDate = moment().startOf('day').toISOString();
      endDate = moment().toISOString();
      break;
    case EmailWarmupStartedAtFilters.Last7Days:
      startDate = moment().subtract(7, 'days').toISOString();
      endDate = moment().toISOString();
      break;
    case EmailWarmupStartedAtFilters.Last14Days:
      startDate = moment().subtract(14, 'days').toISOString();
      endDate = moment().toISOString();
      break;
    case EmailWarmupStartedAtFilters.Last30Days:
      startDate = moment().subtract(30, 'days').toISOString();
      endDate = moment().toISOString();
      break;
    case EmailWarmupStartedAtFilters.MoreThan30Days:
      startDate = null;
      endDate = moment().subtract(30, 'days').toISOString();
      break;
  }
  return { startDate, endDate };
};

export const durationOptionsForEmailWarmup: EmailWarmupDatePickerOption[] = [
  {
    displayText: 'Today',
    value: EmailWarmupStartedAtFilters.Today,
    key: EmailWarmupStartedAtFilters.Today,
    days: 0,
  },
  {
    displayText: 'Last 7 Days',
    value: EmailWarmupStartedAtFilters.Last7Days,
    key: EmailWarmupStartedAtFilters.Last7Days,
    days: 7,
  },
  {
    displayText: 'Last 14 Days',
    value: EmailWarmupStartedAtFilters.Last14Days,
    key: EmailWarmupStartedAtFilters.Last14Days,
    days: 14,
  },
  {
    displayText: 'Last 30 Days',
    value: EmailWarmupStartedAtFilters.Last30Days,
    key: EmailWarmupStartedAtFilters.Last30Days,
    days: 30,
  },
  {
    displayText: 'More than 30 days',
    value: EmailWarmupStartedAtFilters.MoreThan30Days,
    key: EmailWarmupStartedAtFilters.MoreThan30Days,
    days: Infinity,
  },
];

/**
 * Retrieves all the value of the filter.
 * @summary - Gets all the values of the applied filters using the value Key mentioned in filter properties
 * @param  {EmailAccountsFilters} filterName - Name of the filter.
 * @param  {EmailAccountWarmupFilters} filter - Applied filters.
 * @returns {string[]} Values of the filter.
 */
export const getFilterValues = (
  filterName: EmailAccountsFilters,
  filter: EmailAccountWarmupFilters,
): string[] => map(filter, filterToProperties[filterName].valueKey);

/**
 * Resolves the filters to query string
 * @param  {Record<EmailAccountsFilters, any>} appliedFilters - applied filters.
 * @returns {Record<EmailAccountsFilters, string>} Returns the Object having the applied filter and its corresponding query string
 */
type ResolveFilters = Record<EmailAccountsFilters, string> & {
  // isSequenceNone: boolean;
};

export const resolveFilters = (
  appliedFilters: Partial<Record<EmailAccountsFilters, any>>,
): ResolveFilters => {
  const payload: ResolveFilters = {
    tags: '',
    status: '',
    'created date': '',
  };

  Object.entries(appliedFilters).forEach(([filter, values]) => {
    payload[filter as EmailAccountsFilters] = '';
    let filterValues: any = [...(values || [])];

    if (filterValues.some((item) => item?.isNoneSequence === true)) {
      // payload.isSequenceNone = true;
      filterValues = filterValues.filter((item) => item.id !== null);
    }

    const filterVal = getFilterValues(
      filter as EmailAccountsFilters,
      filterValues,
    ).join(',');
    filterVal && (payload[filter] += filterVal);
  });

  return payload;
};

export const setEmailAccountsFiltersInLocalStore = (
  filters: EmailAccountFilters,
) => {
  localStorage.setItem('p-f', JSON.stringify(filters));
};

export const getEmailAccountsFiltersFromLocalStore = (): EmailAccountFilters =>
  JSON.parse(localStorage.getItem('p-f'));

export const removeEmailAccountsFiltersFromLocalStore = (): void => {
  localStorage.removeItem('p-f');
};

/**
 * Returns the extended params of an API with the applied filters.
 * @param  {Record<string, any>} params - Params of a API
 * @param  {EmailAccountWarmupFilters} filters - Applied filters.
 */

export const getEmailWarmupParams = (
  params: Record<string, any>,
  filters: EmailAccountWarmupFilters,
) => {
  const updatedParams: Record<string, any> = { ...params };

  if (filters.tags && Array.isArray(filters.tags)) {
    updatedParams.tags = filters.tags
      .filter((tag) => tag && tag !== null)
      .map((tag) => Number(tag.id));
  }

  if (filters.status && Array.isArray(filters.status)) {
    updatedParams.status = filters.status
      .filter((status) => status && status.id !== undefined)
      .map((status) => String(status.id));
  }

  if (filters.warmupStartDateFrom && filters.warmupStartDateTo) {
    const { startDate, endDate } = filters.warmupStartDateFrom[0];
    updatedParams.warmupStartDateFrom = startDate;
    updatedParams.warmupStartDateTo = endDate;
  }

  return updatedParams;
};

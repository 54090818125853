import { DateTime } from 'luxon';
import { Order } from '../../shared/enums/order';
import { RequestStatus } from '../../shared/enums/request-status';
import { EmailAccountMethod } from '../settings/enums/email-account';
import { EmailAccount } from '../settings/types/email-account';
import { EmailAccountsFilters, EmailWarmupStatus } from './enums';

// ############### State Data Types ####################

export type EmailWarmupAccount = EmailAccount & {
  deliverabilityScore: number;
  sentToday: string;
  receivedToday: string;
  sentLast7Days: string;
  receivedLast7Days: string;
  warmupStatus: number;
  errorMessage?: string;
};

export type EmailSent = {
  sentEmail: number;
  performanceStats: number;
};

export type EmailReceived = {
  receivedEmail: number;
  performanceStats: number;
};

export type EmailAccountWarmupSentReport = {
  today: EmailSent;
  lastSevenDays: EmailSent;
  lastThirtyDays: EmailSent;
};

export type EmailAccountWarmupReceivedReport = {
  today: EmailReceived;
  lastSevenDays: EmailReceived;
  lastThirtyDays: EmailReceived;
};

export type EmailAccountWarmupSentReceivedReport = {
  sent: EmailAccountWarmupSentReport;
  received: EmailAccountWarmupReceivedReport;
};

export type EmailAccountWarmupDeliverabilityReport = {
  deliverabilityPercentage: number;
  total: number;
  inbox: number;
  spam: number;
};

export type EmailAccountWarmupSettings = {
  warmUpInitialSendingLimit: number;
  increaseEmailsByNumber: number;
  maxSendingLimit: number;
  replyRate: number;
  senderName: string;
  warmUpFolder: string;
};

export type EmailAccountWarmupList = {
  id: number;
  fromEmail: string;
  status: number;
  type: number;
  warmupStatus: string;
  isToggled: boolean;
};

// ############### Components Props Types ####################

export type EmailWarmupHeaderProps = {
  emailWarmupHeaderWithReport: boolean;
};

export type EmailWarmupListProps = {
  data: any[];
  onRowClickHandler: (id: number) => void;
  filters: EmailAccountWarmupFilters;
  onFilterChange: (filters: EmailAccountWarmupFilters) => void;
  totalEmailWarmupAccounts: number;
  onEmailWarmupToggle: (
    check: boolean,
    row: EmailAccountWarmupList,
    event: any,
  ) => void;
  isLoading: boolean;
  sendDeleteEmailAccountRequest: (emailAccountId: number) => void;
  isDeleteEmailAccountRequestPending: boolean;
  sendConnectEmailAccountRequest: (emailMethod, emailId) => void;
  sendDisconnectEmailAccountRequest: (emailAccountId) => void;
  isDisconnectEmailAccountRequestPending: boolean;
  deleteRequestStatus: string;
  disconnectEmailAccountRequestStatus: string;
  showEmailWarmupSettings: (emailAccountId: number) => void;
  onEmailConnectFromTableRow: (
    method: EmailAccountMethod,
    senderName: string,
    senderEmail: string,
    emailAccountId: number,
  ) => void;
  onEditEmailSettings: (emailAccountId: number, event: any) => void;
  sendGetSmtpImapAccountDetailsRequestStatus: RequestStatus;
  selectAllContact: (rows: any, status: any) => void;
  selectSingleContact: (row, status) => void;
  clearSelection: () => void;
  tableRef: any;
};

export type EmailWarmupReportHeaderProps = {
  emailAccountId: number;
  emailAccountWarmupList: EmailAccountWarmupList[];
  showEmailWarmupSettings: () => void;
  selectedEmailAccountWarmup: EmailAccountWarmupList;
  setSelectedEmailAccountWarmup: (payload: EmailAccountWarmupList) => void;
  onEmailWarmupToggle: (check: boolean, row: EmailAccountWarmupList) => void;
};

export type EmailWarmupReportStatsProps = {
  label: string;
  value: number;
  performance: number;
};

export type EmailWarmupSettingsProps = {
  emailAccountId: number;
  show: boolean;
  isBulkAction?: boolean;
  onClose: () => void;
  onSubmit: (payload: EmailAccountWarmupSettingsPayload) => void;
  emailAccountWarmupSettings: EmailAccountWarmupSettings;
  getEmailAccountWarmupSettingsRequestStatus: RequestStatus;
  saveEmailAccountWarmupSettingsRequestStatus: RequestStatus;
};

export type EmailWarmupFilterModalProps = {
  show: any;
  onClose: () => void;
  except?: any;
  onSubmit: (updatedFilters: any) => void;
  emailWarmupAccounts: boolean;
};

export type EmailWarmupStatusCellProps = {
  warmupStatus: EmailWarmupStatus;
  errorMessage: string;
};

export type EmailAddressCellProps = {
  cell: any;
  row: Record<any, any>;
  onRowClickHandler: (id: number) => void;
  onEmailConnectFromTableRow: (
    method: EmailAccountMethod,
    senderName: string,
    senderEmail: string,
    emailAccountId: number,
  ) => void;
  onEditEmailSettings: (emailAccountId: number, row: any) => void;
  sendGetSmtpImapAccountDetailsRequestStatus: RequestStatus;
};

export type EmailWarmupEmptyListProps = {
  onAddEmailAccountBtnClicked: VoidFunction;
  // viewEmailFiltersModal: VoidFunction;
};

export type EmailWarmupListHeaderProps = {
  onTableFilterChange: (filters: EmailAccountWarmupFilters) => void;
  onAddEmailAccountBtnClicked: VoidFunction;
  isLoading: boolean;
  viewEmailFiltersModal: VoidFunction;
  selectedEmailAccounts: EmailWarmupAccount[];
  emailAccountTagsList: EmailAccountTag[];
  isActionDisabled: boolean;
  onBulkEditEmailSettings: VoidFunction;
  onEnableDisableEmailWarmup: (action: ApiAction) => void;
  onRefresh: VoidFunction;
  filters: EmailAccountWarmupFilters;
};

export type DeliverabilityStatProps = {
  label: string;
  value: number | string;
};

export type EmailWarmupDeliverabilityReportProps = {
  deliverabilityReport: EmailAccountWarmupDeliverabilityReport;
};

export type EmailWarmupSentReportProps = {
  sentReport: EmailAccountWarmupSentReport;
};

export type EmailWarmupReceivedReportProps = {
  receivedReport: EmailAccountWarmupReceivedReport;
};

export type EmailWarmupReportChartProps = {
  deliverabilityReport: EmailAccountWarmupDeliverabilityReport;
};

export type EmailWarmupAccountSelect = {
  key: number;
  id: number;
  email: string;
  warmupStatus: string;
  isToggled: boolean;
};

export type EmailWarmupSettingsInputProps = {
  name: string;
  label: string;
  value: string;
  error: string;
  onChange: (value: string) => void;
};

export type EmailWarmupSettingsNumberInputProps = {
  name: string;
  label: string;
  recommendedValue: number | string;
  minValue?: number;
  value: number;
  error: string;
  onChange: (value: string) => void;
  isAdvancedWarmupEnable?: boolean;
};

export type EmailAccountFilters = {
  tags?: TagsObject[];
  status?: StatusObject[];
  'created date'?: DateTime[];
  warmupStartDateFrom?: DateTime;
  warmupStartDateTo?: DateTime;
  displayText?: string;
};

export type EmailAccountFilterResetArg = EmailAccountsFilters | 'all';

export type StatusObject = {
  id: number[];
  name: string[];
};

export type TagsObject = {
  id: number[];
  name: string[];
};

// ############### API Payload Types ####################

export enum EmailAccountWarmupSortKey {
  DeliverabilityRate = 'deliverabilityRate',
  CreatedAt = 'createdAt',
  // SentToday = 'sent-today',
  // ReceivedToday = 'received-today',
}

export type EmailAccountWarmupFilters = {
  page?: number;
  limit?: number;
  search?: string;
  order?: Order;
  tags?: TagsObject[];
  status?: StatusObject[];
  warmupStartDateFrom?: DateTime;
  warmupStartDateTo?: DateTime;
  sortByKey?: EmailAccountWarmupSortKey;
};

export type EmailAccountWarmupFilterDropdowns = {
  tags?: TagsObject[];
  status?: StatusObject[];
  warmupDateFilter?: string[];
};

export type EmailAccountId = {
  id: number;
};

export type EmailWarmupDeliverabilityPayload = {
  id: number;
  timeFrame: string;
};

export type EmailAccountWarmupSettingsPayload = {
  id: number;
} & EmailAccountWarmupSettings;

export type BulkEmailAccountWarmupSettingsPayload = {
  emailAccountIds: number[];
} & EmailAccountWarmupSettings;

export type BulkAllEmailAccountWarmupSettingsPayload = {
  deSelectedEmailAccountIds: number[];
  emailFilters?: EmailAccountBulkActionFilters;
} & EmailAccountWarmupSettings;

export type EmailAccountTag = {
  id: number;
  name: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt?: Date | string;
  shAccountId: number;
  userId: number;
};

export type EmailAccountTagsAssignPayload = {
  ids: number[];
  emailAccountIds: number[];
  newTags: string[];
};

export type EmailAccountTagsBulkAssignPayload = {
  ids: number[];
  deSelectedEmailAccountIds?: number[];
  newTags: string[];
  emailFilters?: EmailAccountBulkActionFilters;
};

export type EmailAccountTagsUnassignPayload = {
  tagIds: number[];
  emailAccountIds: number[];
};

export type EmailAccountTagsBulkUnassignPayload = {
  ids: number[];
  deSelectedEmailAccountIds?: number[];
  emailFilters?: EmailAccountBulkActionFilters;
};

export type EmailAccountBulkActionFilters = {
  search?: string;
  tags?: TagsObject[];
  status?: StatusObject[];
  warmupStartDateFrom?: DateTime;
  warmupStartDateTo?: DateTime;
};

export enum ApiAction {
  Enable = 'enable',
  Disable = 'disable',
  Delete = 'delete',
}

import { hideLoading, showLoading } from 'react-redux-loading-bar';
import i18n from '../../../../../i18n';
import { StatusBadgeVariant } from '../../../../../shared/design-system/components/molecules/status-badge/types';
import Tag from '../../../../../shared/design-system/components/tag';
import toaster, { Theme } from '../../../../../shared/toaster';
import store from '../../../../../store';
import {
  EmailAccountMethod,
  EmailAccountType,
  EmailServiceProvider,
} from '../../../enums/email-account';
import { EmailAccountSettingCode } from '../../../enums/email-account-settings';
import { Encryption } from '../../../enums/smtp-imap-encryption';
import { SMTPIMAP } from '../../../enums/smtp-or-imap';
import { EmailAccountSettings } from '../../../types/email-account-settings';
import { Images } from '../../../../../shared/app-constants';
import {
  EmailProviderDropDown,
  SmtpImapProviders,
  LastTestConnection,
} from '../components/connect-smtp-imap-account-modal/types';

const freeGmailAccounts = ['gmail.com', 'googlemail.com'];

export const getQuotaChangeConfirmationMessage = (
  emailsSent: number,
  emailsWouldBeAbleToSend: number,
) => {
  if (emailsWouldBeAbleToSend > 0) {
    return `${i18n.t('messages.you_have_already_sent')} ${emailsSent} ${i18n.t(
      'messages.emails_today',
    )} ${emailsWouldBeAbleToSend} ${i18n.t('messages.more_emails_today')}`;
  }
  return `${i18n.t('messages.you_have_already_sent')} ${emailsSent} ${i18n.t(
    'messages.limit_restoration_tomorrow',
  )}`;
};

export const getEmailAccountType = (emailAccount) =>
  emailAccount.type === EmailAccountType.Gmail
    ? EmailAccountMethod.Gmail
    : EmailAccountMethod.Microsoft;

export const getEmailAccountIdentifier = (emailAccount) =>
  emailAccount.type === EmailAccountType.Gmail
    ? Images.GooglePlus
    : Images.MicrosoftSM;

export const getEmailAccountTagValue = (emailAccount) =>
  emailAccount.isDefault ? 'Default' : 'Make It Default';

export const getEmailAccountTagColor = (emailAccount) =>
  emailAccount.isDefault ? Tag.Color.Green : undefined;

export const getEmailAccountTagClassName = (emailAccount) =>
  emailAccount.isDefault ? '' : 'tag-visibility';

export const getOverlayTooltipMessage = (emailAccount) =>
  emailAccount.fromName ? emailAccount.fromName : emailAccount.fromEmail;

export const getAvailableQuota = (quota) => {
  const availableQuota = quota ? parseInt(quota, 10) : quota;
  return availableQuota > 0 ? availableQuota : 0;
};

export const getSendVsLimit = (settings: EmailAccountSettings[]) => {
  let availableQuota = settings.find(
    (setting) => setting.code === EmailAccountSettingCode.AvailableQuota,
  )?.value;

  const dailySendingLimit = settings.find(
    (setting) => setting.code === EmailAccountSettingCode.DailySendingLimit,
  )?.value;

  availableQuota = getAvailableQuota(availableQuota);
  return `${availableQuota}/${dailySendingLimit}`;
};

export const showHideLoading = (isLoading, prevPropsIsLoading) => {
  if (isLoading !== prevPropsIsLoading) {
    if (isLoading) {
      store.dispatch(showLoading());
    } else {
      store.dispatch(hideLoading());
    }
  }
};

export const showToasterWithErrorCheck = (error) => {
  if (error) {
    if (typeof error === 'string') {
      toaster.error(error, {
        theme: Theme.New,
        showCloseIcon: true,
        delay: 10000,
      });

      return;
    }
    toaster.error(error.message, {
      theme: Theme.New,
      showCloseIcon: true,
      delay: 10000,
    });
  }
};

export const shouldShowAddEmailAccountModal = (
  showAddEmailAccountModalProp,
  prevShowAddEmailAccountModalProp,
): boolean =>
  showAddEmailAccountModalProp !== prevShowAddEmailAccountModalProp &&
  showAddEmailAccountModalProp;

export const isSavedSMTPAccount = (emailAccount) =>
  emailAccount.smtpImapConnectionPayload
    ? emailAccount?.smtpImapConnectionPayload?.smtpConnectionErrorDetails !==
        null ||
      emailAccount?.smtpImapConnectionPayload?.imapConnectionErrorDetails !==
        null
    : false;

const smtpHosts = {
  [SmtpImapProviders.GmailGsuite]: 'smtp.gmail.com',
  [SmtpImapProviders.Office365]: 'smtp.office365.com',
  [SmtpImapProviders.OutlookHotmailLive]: 'smtp-mail.outlook.com',
  [SmtpImapProviders.YahooMail]: 'smtp.mail.yahoo.com',
  [SmtpImapProviders.Zoho]: 'smtp.zoho.com',
  [SmtpImapProviders.Godaddy]: 'smtpout.secureserver.net',
  [SmtpImapProviders.Yandex]: 'smtp.yandex.com',
  [SmtpImapProviders.Sendgrid]: 'smtp.sendgrid.net',
  [SmtpImapProviders.Mailgun]: 'smtp.mailgun.org',
  [SmtpImapProviders.AmazonSES]: 'smtp.amazonaws.com',
  [SmtpImapProviders.Other]: '',
};

const smtpPorts = {
  [SmtpImapProviders.GmailGsuite]: 465,
  [SmtpImapProviders.Office365]: 587,
  [SmtpImapProviders.OutlookHotmailLive]: 587,
  [SmtpImapProviders.YahooMail]: 465,
  [SmtpImapProviders.Zoho]: 465,
  [SmtpImapProviders.Godaddy]: 465,
  [SmtpImapProviders.Yandex]: 465,
  [SmtpImapProviders.Sendgrid]: 465,
  [SmtpImapProviders.Mailgun]: 465,
  [SmtpImapProviders.AmazonSES]: 465,
  [SmtpImapProviders.Other]: 465,
};

const smtpEncryptions = {
  [SmtpImapProviders.GmailGsuite]: Encryption.SSL,
  [SmtpImapProviders.Office365]: Encryption.TLS,
  [SmtpImapProviders.OutlookHotmailLive]: Encryption.TLS,
  [SmtpImapProviders.YahooMail]: Encryption.SSL,
  [SmtpImapProviders.Zoho]: Encryption.SSL,
  [SmtpImapProviders.Godaddy]: Encryption.SSL,
  [SmtpImapProviders.Yandex]: Encryption.SSL,
  [SmtpImapProviders.Sendgrid]: Encryption.SSL,
  [SmtpImapProviders.Mailgun]: Encryption.SSL,
  [SmtpImapProviders.AmazonSES]: Encryption.SSL,
  [SmtpImapProviders.Other]: Encryption.SSL,
};

const imapHosts = {
  [SmtpImapProviders.GmailGsuite]: 'imap.gmail.com',
  [SmtpImapProviders.Office365]: 'outlook.office365.com',
  [SmtpImapProviders.OutlookHotmailLive]: 'imap-mail.outlook.com',
  [SmtpImapProviders.YahooMail]: 'imap.mail.yahoo.com',
  [SmtpImapProviders.Zoho]: 'imap.zoho.com',
  [SmtpImapProviders.Godaddy]: 'imap.secureserver.net',
  [SmtpImapProviders.Yandex]: 'imap.yandex.com',
  [SmtpImapProviders.Sendgrid]: 'imap.sendgrid.net',
  [SmtpImapProviders.Mailgun]: 'imap.mailgun.org',
  [SmtpImapProviders.AmazonSES]: 'imap.amazonaws.com',
  [SmtpImapProviders.Other]: '',
};

const imapPorts = {
  [SmtpImapProviders.GmailGsuite]: 993,
  [SmtpImapProviders.Office365]: 993,
  [SmtpImapProviders.OutlookHotmailLive]: 993,
  [SmtpImapProviders.YahooMail]: 993,
  [SmtpImapProviders.Zoho]: 993,
  [SmtpImapProviders.Godaddy]: 993,
  [SmtpImapProviders.Yandex]: 993,
  [SmtpImapProviders.Sendgrid]: 993,
  [SmtpImapProviders.Mailgun]: 993,
  [SmtpImapProviders.AmazonSES]: 993,
  [SmtpImapProviders.Other]: 993,
};

const imapEncryptions = {
  [SmtpImapProviders.GmailGsuite]: Encryption.SSL,
  [SmtpImapProviders.Office365]: Encryption.SSL,
  [SmtpImapProviders.OutlookHotmailLive]: Encryption.SSL,
  [SmtpImapProviders.YahooMail]: Encryption.SSL,
  [SmtpImapProviders.Zoho]: Encryption.SSL,
  [SmtpImapProviders.Godaddy]: Encryption.SSL,
  [SmtpImapProviders.Yandex]: Encryption.SSL,
  [SmtpImapProviders.Sendgrid]: Encryption.SSL,
  [SmtpImapProviders.Mailgun]: Encryption.SSL,
  [SmtpImapProviders.AmazonSES]: Encryption.SSL,
  [SmtpImapProviders.Other]: Encryption.SSL,
};

export const getDefaultValuesForSmtpImapProviders = (
  provider: SmtpImapProviders,
) => {
  const values = {
    smtpHostValue: smtpHosts[provider],
    smtpPortValue: smtpPorts[provider],
    smtpEncryptionValue: smtpEncryptions[provider],
    imapHostValue: imapHosts[provider],
    imapPortValue: imapPorts[provider],
    imapEncryptionValue: imapEncryptions[provider],
  };
  return values;
};

export const getDefaultSmtpImapValues = (value) => {
  if (value === SmtpImapProviders.GmailGsuite) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.GmailGsuite);
  }

  if (value === SmtpImapProviders.Office365) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Office365);
  }

  if (value === SmtpImapProviders.OutlookHotmailLive) {
    return getDefaultValuesForSmtpImapProviders(
      SmtpImapProviders.OutlookHotmailLive,
    );
  }

  if (value === SmtpImapProviders.YahooMail) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.YahooMail);
  }

  if (value === SmtpImapProviders.Zoho) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Zoho);
  }

  if (value === SmtpImapProviders.Godaddy) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Godaddy);
  }

  if (value === SmtpImapProviders.Yandex) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Yandex);
  }

  if (value === SmtpImapProviders.Sendgrid) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Sendgrid);
  }

  if (value === SmtpImapProviders.Mailgun) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Mailgun);
  }

  if (value === SmtpImapProviders.AmazonSES) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.AmazonSES);
  }

  if (value === SmtpImapProviders.Other) {
    return getDefaultValuesForSmtpImapProviders(SmtpImapProviders.Other);
  }

  return {
    smtpHostValue: null,
    smtpPortValue: null,
    smtpEncryptionValue: Encryption.SSL,
    imapHostValue: null,
    imapPortValue: null,
    imapEncryptionValue: Encryption.SSL,
  };
};

const getEmailProviderListValue = (provider) => ({
  key: provider,
  value: provider,
});

export const getEmailProviderValue = (key) => {
  const emailProviderLists = [
    {
      key: 'smtp.gmail.com',
      value: getEmailProviderListValue(SmtpImapProviders.GmailGsuite),
    },
    {
      key: 'smtp.office365.com',
      value: getEmailProviderListValue(SmtpImapProviders.Office365),
    },
    {
      key: 'smtp-mail.outlook.com',
      value: getEmailProviderListValue(SmtpImapProviders.OutlookHotmailLive),
    },
    {
      key: 'smtp.mail.yahoo.com',
      value: getEmailProviderListValue(SmtpImapProviders.YahooMail),
    },
    {
      key: 'smtp.zoho.com',
      value: getEmailProviderListValue(SmtpImapProviders.Zoho),
    },
    {
      key: 'smtpout.secureserver.net',
      value: getEmailProviderListValue(SmtpImapProviders.Godaddy),
    },
    {
      key: 'smtp.yandex.com',
      value: getEmailProviderListValue(SmtpImapProviders.Yandex),
    },
    {
      key: 'smtp.sendgrid.net',
      value: getEmailProviderListValue(SmtpImapProviders.Sendgrid),
    },
    {
      key: 'smtp.mailgun.org',
      value: getEmailProviderListValue(SmtpImapProviders.Mailgun),
    },
    {
      key: 'smtp.amazonaws.com',
      value: getEmailProviderListValue(SmtpImapProviders.AmazonSES),
    },
  ];

  const extractDomain = (inputKey) => {
    const domain = inputKey.split('.');
    if (domain.length >= 2) {
      return domain.slice(-2).join('.');
    }
    return inputKey;
  };

  const targetDomain = extractDomain(key);

  const result = emailProviderLists.find(
    (e) => extractDomain(e.key) === targetDomain,
  );

  return result?.value || { key: 'Other', value: 'Other' };
};

export const emailProviderDropDown: EmailProviderDropDown[] = [
  { key: SmtpImapProviders.GmailGsuite, value: SmtpImapProviders.GmailGsuite },
  { key: SmtpImapProviders.YahooMail, value: SmtpImapProviders.YahooMail },
  { key: SmtpImapProviders.Zoho, value: SmtpImapProviders.Zoho },
  { key: SmtpImapProviders.Godaddy, value: SmtpImapProviders.Godaddy },
  { key: SmtpImapProviders.Sendgrid, value: SmtpImapProviders.Sendgrid },
  { key: SmtpImapProviders.Mailgun, value: SmtpImapProviders.Mailgun },
  { key: SmtpImapProviders.AmazonSES, value: SmtpImapProviders.AmazonSES },
  { key: SmtpImapProviders.Yandex, value: SmtpImapProviders.Yandex },
  { key: SmtpImapProviders.Other, value: SmtpImapProviders.Other },
];

export const encryptionOption = [
  {
    label: Encryption.SSL,
    value: Encryption.SSL,
  },
  {
    label: Encryption.TLS,
    value: Encryption.TLS,
  },
  {
    label: 'None',
    value: 'none',
  },
];

const getIsConnectionError = (
  requestFrom,
  connectionType,
  testConnectionResponse,
  connectSmtpImapResponse,
  smtpImapAccountDetailsResponse,
  updateSmtpImapAccountDetailsResponse,
  lastTestConnection,
) => {
  let isConnectionError;
  let connectionErrorMessage = '';

  if (lastTestConnection === LastTestConnection.Test) {
    isConnectionError = testConnectionResponse?.isConnectionEstablished;
    connectionErrorMessage = testConnectionResponse?.error;
  } else if (lastTestConnection === LastTestConnection.ConnectAndSave) {
    isConnectionError =
      connectSmtpImapResponse?.[connectionType]?.isConnectionEstablished;
    connectionErrorMessage = connectSmtpImapResponse?.[connectionType]?.error;
  } else if (lastTestConnection === LastTestConnection.Update) {
    isConnectionError =
      updateSmtpImapAccountDetailsResponse?.[connectionType]
        ?.isConnectionEstablished;
    connectionErrorMessage =
      updateSmtpImapAccountDetailsResponse?.[connectionType]?.error;
  } else if (smtpImapAccountDetailsResponse && requestFrom === SMTPIMAP.IMAP) {
    isConnectionError = !smtpImapAccountDetailsResponse?.imapError
      ?.isErrorInImap;
    connectionErrorMessage =
      smtpImapAccountDetailsResponse?.imapError?.imapConnectionError;
  } else if (smtpImapAccountDetailsResponse && requestFrom === SMTPIMAP.SMTP) {
    isConnectionError = !smtpImapAccountDetailsResponse?.smtpError
      ?.isErrorInSmtp;
    connectionErrorMessage =
      smtpImapAccountDetailsResponse?.smtpError?.smtpConnectionError;
  }

  return { isConnectionError, connectionErrorMessage };
};

export const getVariantTypeForSmtpImap = ({
  testConnectionResponse,
  connectSmtpImapResponse,
  smtpImapAccountDetailsResponse,
  updateSmtpImapAccountDetailsResponse,
  requestFrom,
  connectionType,
  lastTestConnection,
}) => {
  let variant: StatusBadgeVariant = StatusBadgeVariant.DANGER;
  const { isConnectionError, connectionErrorMessage } = getIsConnectionError(
    requestFrom,
    connectionType,
    testConnectionResponse,
    connectSmtpImapResponse,
    smtpImapAccountDetailsResponse,
    updateSmtpImapAccountDetailsResponse,
    lastTestConnection,
  );

  if (isConnectionError) {
    variant = StatusBadgeVariant.SUCCESS;
  }

  return { variant, connectionErrorMessage };
};

export const getIsButtonIsLoading = (
  isConnectAccountRequestPending,
  isUpdateSmtpImapAccountRequestPending,
) => isConnectAccountRequestPending || isUpdateSmtpImapAccountRequestPending;

export const getIsButtonDisable = (
  isConnectAndSaveButtonDisable,
  isConnectAccountRequestPending,
  isUpdateSmtpImapAccountRequestPending,
  isSenderValid,
) =>
  isConnectAndSaveButtonDisable ||
  isConnectAccountRequestPending ||
  isUpdateSmtpImapAccountRequestPending ||
  isSenderValid;

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const isSMTPTestButtonDisable = ({
  useUsername,
  username,
  smtpEmailAddress,
  smtpEmailPassword,
  smtpHost,
  smtpPort,
  smtpEncryption,
  senderName,
  emailProvider,
}) => {
  let isUsernameValidate = true;

  if (useUsername) {
    isUsernameValidate = username?.length > 0;
  }

  return (
    isUsernameValidate &&
    smtpEmailAddress?.length > 0 &&
    smtpEmailPassword?.length > 0 &&
    smtpHost?.length > 0 &&
    smtpPort !== null &&
    smtpEncryption?.length > 0 &&
    senderName?.length > 0 &&
    emailProvider !== null
  );
};

export const isIMAPTestButtonDisable = (
  imapEmailAddress,
  imapEmailPassword,
  imapHost,
  imapPort,
  imapEncryption,
) =>
  imapEmailAddress?.length > 0 &&
  imapEmailPassword?.length > 0 &&
  imapHost?.length > 0 &&
  imapPort !== null &&
  imapEncryption?.length > 0;

export const getEmailServiceProvider = (emailProvider, emailAddress) => {
  let provider;

  switch (emailProvider.value) {
    case SmtpImapProviders.GmailGsuite: {
      const domainName = emailAddress.split('@').pop();
      if (freeGmailAccounts.includes(domainName)) {
        provider = EmailServiceProvider.Gmail;
      } else {
        provider = EmailServiceProvider.Gsuite;
      }
      break;
    }
    case SmtpImapProviders.OutlookHotmailLive:
      provider = EmailServiceProvider.Microsoft;
      break;
    case SmtpImapProviders.Office365:
      provider = EmailServiceProvider.O365;
      break;
    case SmtpImapProviders.YahooMail:
      provider = EmailServiceProvider.Yahoo;
      break;
    case SmtpImapProviders.Godaddy:
      provider = EmailServiceProvider.Godaddy;
      break;
    case SmtpImapProviders.Zoho:
      provider = EmailServiceProvider.Zoho;
      break;
    case SmtpImapProviders.Yandex:
      provider = EmailServiceProvider.Yandex;
      break;
    case SmtpImapProviders.Sendgrid:
      provider = EmailServiceProvider.Sendgrid;
      break;
    case SmtpImapProviders.Mailgun:
      provider = EmailServiceProvider.Mailgun;
      break;
    case SmtpImapProviders.AmazonSES:
      provider = EmailServiceProvider.AmazonSES;
      break;
    default:
      provider = EmailServiceProvider.Other;
      break;
  }

  return provider;
};

export const useSameEmailPasswordSwitchDisable = (
  emailAddress,
  emailPassword,
  username = null,
) => {
  const isDisable = emailAddress?.length === 0 || emailPassword?.length === 0;
  if (username) {
    return username?.length === 0 && isDisable;
  }

  return isDisable;
};

export const showTestConnectionSignal = ({
  sendTestConnectionResponse,
  sendConnectSmtpImapResponse,
  sendGetSmtpImapAccountDetailsResponse,
  sendUpdateSmtpImapAccountDetailsResponse,
  badgeVariant,
}) =>
  (sendTestConnectionResponse ||
    sendConnectSmtpImapResponse ||
    sendGetSmtpImapAccountDetailsResponse ||
    sendUpdateSmtpImapAccountDetailsResponse) &&
  badgeVariant === StatusBadgeVariant.DANGER;

export const isUseSameEmailPasswordToggleOn = (
  username,
  smtpEmailAddress,
  smtpEmailPassword,
  imapEmailAddress,
  imapEmailPassword,
) => {
  const isEmailAddressSame =
    smtpEmailAddress === imapEmailAddress || username === imapEmailAddress;
  const isEmailPasswordSame = smtpEmailPassword === imapEmailPassword;

  return isEmailAddressSame && isEmailPasswordSame;
};

export const setHotPortEncryptionValue = (
  option: EmailProviderDropDown,
  {
    smtpHostValue,
    smtpPortValue,
    smtpEncryptionValue,
    imapHostValue,
    imapPortValue,
    imapEncryptionValue,
    setSMTPHost,
    setSMTPPort,
    setSMTPEncryption,
    setIMAPHost,
    setIMAPPort,
    setIMAPEncryption,
  },
) => {
  if (smtpHostValue || option.key === SmtpImapProviders.Other) {
    setSMTPHost();
  }
  if (smtpPortValue || option.key === SmtpImapProviders.Other) {
    setSMTPPort();
  }
  if (smtpEncryptionValue || option.key === SmtpImapProviders.Other) {
    setSMTPEncryption();
  }
  if (imapHostValue || option.key === SmtpImapProviders.Other) {
    setIMAPHost();
  }
  if (imapPortValue || option.key === SmtpImapProviders.Other) {
    setIMAPPort();
  }
  if (imapEncryptionValue || option.key === SmtpImapProviders.Other) {
    setIMAPEncryption();
  }
};

export const isConnectButtonDisable = (isSMTPTestDisable, isIMAPTestDisabled) =>
  isSMTPTestDisable || isIMAPTestDisabled;

export const checkEmailValidationBeforeTesting = ({
  isSmtpEmailValid,
  isImapEmailValid,
  requestFrom,
  isSMTPEmailNotValid,
  isIMAPEmailNotValid,
  onEmailIsValid,
}) => {
  if (!isSmtpEmailValid && requestFrom === SMTPIMAP.SMTP) {
    isSMTPEmailNotValid();
  } else if (!isImapEmailValid && requestFrom === SMTPIMAP.IMAP) {
    isIMAPEmailNotValid();
  } else {
    onEmailIsValid();
  }
};

export const checkEmailValidationBeforeConnecting = ({
  isSmtpEmailValid,
  isImapEmailValid,
  isSMTPEmailNotValid,
  isIMAPEmailNotValid,
  onEmailIsValid,
}) => {
  if (!isSmtpEmailValid) {
    isSMTPEmailNotValid();
  } else if (!isImapEmailValid) {
    isIMAPEmailNotValid();
  } else {
    onEmailIsValid();
  }
};

export const getUsernamePlaceholder = (emailProviderKey: string): string => {
  if (
    emailProviderKey === SmtpImapProviders.AmazonSES ||
    emailProviderKey === SmtpImapProviders.Mailgun
  ) {
    return 'Enter SMTP Username';
  }
  return 'Username';
};

export const getPasswordLabel = (emailProviderKey: string): React.ReactNode => {
  if (emailProviderKey === SmtpImapProviders.GmailGsuite) {
    return 'App Password';
  }
  if (
    emailProviderKey === SmtpImapProviders.Zoho ||
    emailProviderKey === SmtpImapProviders.YahooMail ||
    emailProviderKey === SmtpImapProviders.Yandex ||
    emailProviderKey === SmtpImapProviders.Godaddy
  ) {
    return 'Password';
  }

  return 'Password/API Key';
};

export const getPasswordPlaceholder = (emailProviderKey: string): string => {
  if (emailProviderKey === SmtpImapProviders.GmailGsuite) {
    return 'App Password';
  }
  if (
    emailProviderKey === SmtpImapProviders.Zoho ||
    emailProviderKey === SmtpImapProviders.YahooMail ||
    emailProviderKey === SmtpImapProviders.Yandex ||
    emailProviderKey === SmtpImapProviders.Godaddy
  ) {
    return 'Enter Password';
  }

  return 'Enter Password/API Key';
};

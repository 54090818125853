import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { SkeletonLoading } from '@saleshandy/design-system';
import { Dropdown } from 'react-bootstrap';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import classNames from 'classnames';
import validator from 'validator';
import { ChevronDown, ChevronUp } from '@saleshandy/icons';
import Icon from '../../atoms/icon/icon';
import {
  IProps,
  Column,
  IState,
  Action,
  Sorting,
  TablePageLengthDropDown,
} from './types';
import Switch, { Size } from '../../atoms/switch';
import Checkbox from '../../atoms/checkbox';
import IconText from '../../atoms/icon-text';
import TextStacksDirectional from '../../molecules/text-stacks-directional';
import ContactNameField from '../../molecules/contact-name-field';
import { difference } from '../../../../utils/set';
import { SequenceNameField } from '../../molecules/sequence-name-field';
import { TeamMemberNameField } from '../../molecules/team-member-name-field';
import { OverlayTooltip, Placement } from '../../overlay';
import PaginationWrapper from './pagination-wrapper';
import { constants } from '../../../../enums/constants';
import ImageIcon from '../../../../components/images/image-icon';
import { Images } from '../../../../app-constants';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import EmptyList from '../../molecules/empty-list/empty-list';
import {
  getFinalColumns,
  getKeyAndId,
  getNoDataClassName,
  getShouldShowPagination,
  getTotalSize,
  showingLengthPerPageOptions,
} from './helper';
import Select from '../../select';
import Input from '../../input/input';

class Table extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedRows: [],
      goToPage: 1,
      showRowsPerPage: showingLengthPerPageOptions[0],
      showBounce: false,
      isError: false,
    };
    this.onSelectedRowsUpdate = this.onSelectedRowsUpdate.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onRowSelectAllHandler = this.onRowSelectAllHandler.bind(this);
    this.getCoreComponent = this.getCoreComponent.bind(this);
    this.resetSelectedRows = this.resetSelectedRows.bind(this);
    this.selectAllCurrentPageProspects = this.selectAllCurrentPageProspects.bind(
      this,
    );
  }

  componentDidMount() {
    const { tableRef } = this.props;

    if (tableRef) {
      tableRef(this);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetSelected) {
      this.onSelectedRowsUpdate([]);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showRowsPerPage: showingLengthPerPageOptions[0],
      });
    }
  }

  componentWillUnmount() {
    const { tableRef } = this.props;

    if (tableRef) {
      tableRef(undefined);
    }
  }

  onSelectedRowsUpdate(value) {
    this.setState({ selectedRows: value });
  }

  onChangeHandler(
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
    cell: any,
    row: any,
    index: number,
  ) {
    const { onChange } = this.props;
    onChange(checked, event, cell, row, index);
  }

  onRowSelect(row: any, isSelect: boolean) {
    const { selectedRows } = this.state;
    const { onRowSelect } = this.props;
    if (isSelect) {
      this.setState(
        () => ({
          selectedRows: [...selectedRows, row.id],
        }),
        () => {
          !!onRowSelect && onRowSelect(row, isSelect);
        },
      );
    } else {
      this.setState(
        () => ({
          selectedRows: selectedRows.filter((x) => x !== row.id),
        }),
        () => {
          !!onRowSelect && onRowSelect(row, isSelect);
        },
      );
    }
  }

  onRowSelectAllHandler(isSelect: boolean, rows: any) {
    const ids = rows.map((r) => r.id);
    const { selectedRows } = this.state;
    const { onRowSelectAll } = this.props;

    if (isSelect) {
      this.setState(
        () => ({
          selectedRows: [...selectedRows, ...ids],
        }),
        () => {
          !!onRowSelectAll && onRowSelectAll(rows, isSelect);
        },
      );
    } else {
      const uniqueRows = rows.filter(
        (row, index, self) => index === self.findIndex((r) => r.id === row.id),
      );

      this.setState(
        () => {
          const setOfCurrentState = new Set(selectedRows);
          const setOfUnselectedContacts = new Set(ids);

          return {
            selectedRows: Array.from(
              difference(setOfCurrentState, setOfUnselectedContacts),
            ),
          };
        },
        () => {
          !!onRowSelectAll && onRowSelectAll(uniqueRows, isSelect);
        },
      );
    }
  }

  getCoreComponent(
    component: string,
    cell: any,
    row: any,
    index: number,
    componentClasses: string,
    onClick: any,
    switchOffTooltip = 'Resume',
    switchOnTooltip = 'Pause',
    tooltipPlacement = Placement.Bottom,
    overlayTooltipClass = '',
  ) {
    if (cell !== null && cell !== undefined) {
      const tooltipText = cell ? switchOnTooltip : switchOffTooltip;
      switch (component) {
        case 'switch':
          return (
            <Switch
              placement={tooltipPlacement}
              tooltip={tooltipText}
              className={`table-switch ${componentClasses}`}
              overlayTooltipClass={overlayTooltipClass}
              checked={cell}
              size={Size.Small}
              onChange={(checked, event) =>
                this.onChangeHandler(checked, event, cell, row, index)
              }
            />
          );
        case 'checkbox':
          return (
            <Checkbox
              className={componentClasses}
              checked={cell}
              onChange={(checked, event) =>
                this.onChangeHandler(checked, event, cell, row, index)
              }
            />
          );
        case 'text-stack':
          return <TextStacksDirectional stacks={cell} />;
        case 'icon-text':
          return <IconText text={cell.text} icon={cell.icon} />;
        case 'contact-name-field':
          return <ContactNameField cell={cell} onClick={onClick} />;
        case 'gray-text':
          return <span style={{ color: '#595959' }}>{cell}</span>;
        case 'sequence-name-field':
          return <SequenceNameField name={cell} onClick={() => onClick(row)} />;
        case 'team-member-name-field':
          return <TeamMemberNameField name={cell} />;
        default:
          break;
      }
    }
    return null;
  }

  resetSelectedRows = () => {
    this.setState({ selectedRows: [] });
  };

  selectAllCurrentPageProspects = () => {
    const { data, selectedRowsDetails } = this.props;

    const temp = data.filter(
      ({ id }) => !selectedRowsDetails?.deSelectedContactIds?.includes(id),
    );

    this.onRowSelectAllHandler(true, temp);
  };

  sortCaret = (sortOptions, order: Sorting) => {
    if (!sortOptions) {
      return null;
    }
    if (order === Sorting.Ascending) {
      return <ChevronUp width={14} height={14} className="action" />;
      // return <Icon identifier="arrow-up" />;
    }
    if (order === Sorting.Descending) {
      return <ChevronDown width={14} height={14} className="action" />;
      // return <Icon identifier="arrow-down" />;
    }
    return null;
  };

  generateFormatter = (columns: Column[]) =>
    columns.map((col: Column, index: number) => {
      const {
        cellClasses,
        componentClasses,
        dataField,
        text,
        component,
        align,
        headerAlign,
        isDummyField,
        onClick,
        style,
        headerStyle,
        sort,
        onSort,
        switchOffTooltip,
        switchOnTooltip,
        tooltipPlacement,
        overlayTooltipClass,
        headerLoadingSkeleton,
        cellLoadingSkeleton,
        isCellClickable = true,
      } = col;
      const { sort: sortOptions, isLoading } = this.props;
      const sortingClass = (
        columnName: string,
        selectedOrder: string,
        sortOrder: string,
      ) => {
        const {
          sort: { dataField: sortDataField },
        } = this.props;
        if (columnName === sortDataField) {
          return sortOrder === selectedOrder ? 'selected' : 'not-selected';
        }
        return '';
      };
      return {
        dataField,
        text,
        classes: cellClasses,
        align,
        headerAlign,
        isDummyField,
        style,
        headerStyle,
        headerClasses: sort,
        sort: false, // Disabling sort handling via bootstrap table
        onSort,
        isCellClickable,
        sortCaret: (order: Sorting) => this.sortCaret(sortOptions, order),
        headerFormatter: (column) => {
          if (isLoading) {
            return headerLoadingSkeleton;
          }

          return (
            <div className="d-flex ">
              <span className="semibold-1 gray-txt-12">{column.text}</span>
              {column.text.length > 0 && col.sort && (
                <div role="button" className="sort-button">
                  <ChevronUp
                    width={14}
                    height={14}
                    className={`action ${sortingClass(
                      column?.dataField,
                      // eslint-disable-next-line react/destructuring-assignment
                      this.props?.sort?.order,
                      'asc',
                    )}`}
                    {...accessibleOnClick(() => {
                      onSort(column?.dataField, 'asc');
                    })}
                  />
                  <ChevronDown
                    width={14}
                    height={14}
                    className={`action ${sortingClass(
                      column?.dataField,
                      // eslint-disable-next-line react/destructuring-assignment
                      this.props?.sort?.order,
                      'desc',
                    )}`}
                    {...accessibleOnClick(() => {
                      onSort(column?.dataField, 'desc');
                    })}
                  />
                  {/* <ImageIcon src={Images.ArrowsSort} /> */}
                </div>
              )}
            </div>
          );
        },
        formatter: (cell, row) => {
          if (isLoading) {
            return cellLoadingSkeleton;
          }

          if (typeof component === 'string') {
            return this.getCoreComponent(
              component,
              cell,
              row,
              index,
              componentClasses,
              onClick,
              switchOffTooltip,
              switchOnTooltip,
              tooltipPlacement,
              overlayTooltipClass,
            );
          }
          if (component instanceof React.Component) {
            return component;
          }
          if (typeof component === 'function') {
            return component(cell, row);
          }
          return cell;
        },
      };
    });

  conditionCheck = (row, action) => {
    let tempRow = row;

    const { condition, conditionKey } = action;

    if (!conditionKey) {
      return true;
    }

    if (row.customDomain) {
      tempRow = { ...row.customDomain };
    }

    if (condition) {
      return tempRow[conditionKey];
    }
    return !tempRow[conditionKey];
  };

  generateDropdownItemsListFromActions = (actions: Action[], row) => {
    const { onAction, isLoading } = this.props;

    const dropdownItems = [];
    const gridItems = [];
    let index = 0;
    actions.forEach((action) => {
      if (action.position === 'out') {
        if (isLoading) {
          gridItems.push(<SkeletonLoading width={24} height={24} circle />);
        } else {
          gridItems.push(
            <OverlayTooltip text={action.displayName} key={action.key}>
              {!action.customIcon ? (
                <Icon
                  key={index}
                  onClick={() => onAction(action.key, row)}
                  identifier={action.icon}
                  className="lfloat gray-txt-6 mx-1"
                />
              ) : (
                <img
                  {...accessibleOnClick(() => onAction(action.key, row))}
                  src={action.icon}
                  alt={action.displayName}
                />
              )}
            </OverlayTooltip>,
          );
        }
      } else {
        this.conditionCheck(row, action) &&
          dropdownItems.push(
            <Dropdown.Item
              onClick={() => onAction(action.key, row)}
              key={index}
              bsPrefix="dropdown-item-custom"
            >
              {!action.customIcon && action.icon && (
                <Icon identifier={action.icon} className="lfloat gray-txt-6" />
              )}

              {action.imageIcon && (
                <ImageIcon src={action.imageIcon} alt="Image icon" />
              )}

              {action.customIcon && action.icon && (
                <img
                  {...accessibleOnClick(() => onAction(action.key, row))}
                  src={action.icon}
                  alt={action.displayName}
                  className="lfloat gray-txt-6"
                />
              )}
              {action.displayName && (
                <span className="gray-txt-10">{action.displayName}</span>
              )}
            </Dropdown.Item>,
          );
      }
      index += 1;
    });

    return { gridItems, dropdownItems };
  };

  generateActionsColumn = (
    actions,
    headerVisibleForGenerateColumn,
    borderOverActions,
  ) => {
    const { isLoading } = this.props;
    return {
      dataField: 'actions',
      text: isLoading ? <SkeletonLoading width={45} height={14} /> : 'Actions',
      headerClasses: 'bs-table-thead',
      formatter: (cell, row) => {
        const generatedDropdownItemsListFromActions =
          typeof actions === 'function'
            ? this.generateDropdownItemsListFromActions(actions(cell, row), row)
            : this.generateDropdownItemsListFromActions(actions, row);
        return generatedDropdownItemsListFromActions !== null ? (
          <div className="grid-flex">
            {/* Showing grid action items */}
            {generatedDropdownItemsListFromActions.gridItems.length !== 0 &&
              generatedDropdownItemsListFromActions.gridItems}

            {/* showing dropdown action items */}
            {generatedDropdownItemsListFromActions.dropdownItems.length !== 0 &&
              isLoading && <SkeletonLoading width={24} height={24} circle />}

            {generatedDropdownItemsListFromActions.dropdownItems.length !== 0 &&
              !isLoading && (
                <div className="grid-action">
                  <Dropdown bsPrefix="dropdown-custom" drop="down">
                    <Dropdown.Toggle
                      bsPrefix="dropdown-toggle-custom"
                      variant="dropdown-custom"
                      id="dropdown-basic"
                    >
                      <Icon identifier="more-vertical-alt" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu bsPrefix="dropdown-menu-custom">
                      {generatedDropdownItemsListFromActions.dropdownItems}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
          </div>
        ) : null;
      },
      isDummyField: true,
      align: 'right',
      headerAlign: 'center',
      headerStyle: {
        display: headerVisibleForGenerateColumn ? 'table-cell' : 'none',
      },
      style: borderOverActions
        ? { borderTop: '1px solid #dee2e6' }
        : { borderTop: 'none' },
    };
  };

  getTotalPages = () => {
    const { paginationOptions } = this.props;
    const { showRowsPerPage } = this.state;

    const totalSize = getTotalSize({ paginationOptions });
    return totalSize / showRowsPerPage?.value;
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      const { onPaginationOptionsChange } = this.props;
      onPaginationOptionsChange({ page, limit: sizePerPage });
      this.setState({ goToPage: page });
    }
  };

  handelChangeGoToPage = (page) => {
    this.setState({
      goToPage: page,
    });
  };

  handleOnBlur = () => {
    const { goToPage } = this.state;
    const totalPage = this.getTotalPages();

    if (
      goToPage > totalPage ||
      goToPage < 1 ||
      !validator.isNumeric(goToPage.toString())
    ) {
      this.setState({
        isError: true,
      });
    } else {
      this.setState({
        isError: false,
      });
    }
  };

  addErrorVariantToInput = () => {
    this.setState({
      showBounce: true,
      isError: true,
    });

    setTimeout(() => {
      this.setState({
        showBounce: false,
      });
    }, 1000);
  };

  isGoToPageValid = (goToPageValue) => {
    const totalPage = this.getTotalPages();

    if (
      goToPageValue < 1 ||
      goToPageValue > totalPage ||
      !validator.isNumeric(goToPageValue.toString())
    ) {
      this.addErrorVariantToInput();

      return false;
    }

    this.setState({
      isError: false,
    });
    return true;
  };

  handleGoToPage = (e) => {
    e.preventDefault();

    const { goToPage, showRowsPerPage } = this.state;

    const checkPageIsValid = this.isGoToPageValid(goToPage);

    if (checkPageIsValid) {
      this.handleTableChange('pagination', {
        page: goToPage,
        sizePerPage: showRowsPerPage?.value,
      });
    }
  };

  handleSelectRowsPerPage = (option) => {
    const { paginationOptions } = this.props;
    const { goToPage } = this.state;

    const totalSize = getTotalSize({ paginationOptions });
    let customShowingKey;
    const renderOption: TablePageLengthDropDown = option;

    if (option.value > totalSize) {
      customShowingKey = totalSize;
      renderOption.key = customShowingKey;
    }

    this.setState({ showRowsPerPage: renderOption });
    this.handleTableChange('pagination', {
      page: goToPage,
      sizePerPage: option?.value,
    });
  };

  render() {
    const {
      data,
      columns,
      actions,
      headerWrapperClasses = '',
      bodyWrapperClasses = '',
      sort,
      tableWrapperClasses = '',
      headerVisibleForGenerateColumn,
      borderOverActions,
      paginationOptions,
      pagination,
      id,
      onRowSelect,
      onRowSelectAll,
      isNewPagination,
      isLoading,
    } = this.props;
    const {
      selectedRows,
      goToPage,
      showRowsPerPage,
      showBounce,
      isError,
    } = this.state;

    const dataColumns = this.generateFormatter(columns);
    const finalColumns = getFinalColumns({
      actions,
      dataColumns,
      generateActionsColumn: this.generateActionsColumn,
      headerVisibleForGenerateColumn,
      borderOverActions,
    });
    const totalSize = getTotalSize({ paginationOptions });

    const paginationShow = getShouldShowPagination({
      pagination,
      totalSize,
      isNewPagination,
    });

    const start =
      (paginationOptions.options.page - 1) * constants.DEFAULT_PAGE_SIZE + 1;
    const end =
      (paginationOptions.options.page - 1) * constants.DEFAULT_PAGE_SIZE +
      data.length;

    // Go To Page classes
    const goToPageInputClasses = classNames([
      'page-number-input',
      {
        'input-bounce': showBounce,
      },
    ]);

    const tableData = isLoading ? Array.from(Array(4).keys()) : data;

    const showCheckboxWithLoader = (options) =>
      isLoading ? (
        <SkeletonLoading width={16} height={16} />
      ) : (
        <Checkbox checked={options.checked} />
      );

    const { onRowClickHandler } = this.props;
    return (
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: paginationOptions.options.page,
          sizePerPage: isNewPagination
            ? showRowsPerPage?.value
            : constants.DEFAULT_PAGE_SIZE,
          totalSize,
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className={`table-list ${tableWrapperClasses}`}>
              <div className="bs-table bs-table-small">
                <div className="bs-table-container">
                  <div className="bs-table-content">
                    <BootstrapTable
                      key={getKeyAndId(id)}
                      id={getKeyAndId(id)}
                      pagination={!paginationShow && paginationFactory({})}
                      remote
                      selectRow={
                        onRowSelect && onRowSelectAll
                          ? {
                              mode: 'checkbox',
                              selected: selectedRows,
                              onSelect: this.onRowSelect,
                              onSelectAll: this.onRowSelectAllHandler,
                              selectionHeaderRenderer: showCheckboxWithLoader,
                              selectionRenderer: showCheckboxWithLoader,
                              headerColumnStyle: { width: '20px' },
                            }
                          : undefined
                      }
                      sort={
                        sort && {
                          dataField: sort.dataField,
                          order: sort.order,
                        }
                      }
                      bordered={false}
                      classes="table-organism"
                      keyField="id"
                      data={tableData}
                      columns={finalColumns.map((column) => ({
                        ...column,
                        events: {
                          onClick: (e, col, columnIndex, row) => {
                            if (col.text !== '' && col.isCellClickable) {
                              onRowClickHandler?.(row.id);
                            }
                          },
                        },
                      }))}
                      bodyClasses={`table-organism-body ${bodyWrapperClasses} ${getNoDataClassName(
                        tableData,
                      )}`}
                      headerWrapperClasses={`table-organism-header ${headerWrapperClasses}`}
                      onTableChange={this.handleTableChange}
                      noDataIndication={
                        !isLoading ? (
                          <EmptyList
                            title="No Result Found!"
                            description=""
                            imgSrc={Images.EmptyData1}
                            isVertical={true}
                          />
                        ) : null
                      }
                      {...paginationTableProps}
                    />
                  </div>
                  {!isLoading && paginationShow && (
                    <div
                      className={classNames([
                        'pagination-container d-flex',
                        {
                          'pagination-container-new': isNewPagination,
                        },
                      ])}
                    >
                      <div className="pagination-row d-flex">
                        {isNewPagination ? (
                          <>
                            <div className="pagination-select-count d-flex align-items-center">
                              <span className="regular-2 popover-arrow-color-txt mr-2">
                                Showing
                              </span>
                              <Select<TablePageLengthDropDown>
                                options={showingLengthPerPageOptions}
                                selectedOptionKey={showRowsPerPage?.key}
                                selectedOptionRenderer={([option]) => (
                                  <span className="blue-txt-15">
                                    {option?.key}
                                  </span>
                                )}
                                onChange={([option]) =>
                                  this.handleSelectRowsPerPage(option)
                                }
                                optionRenderer={(option) => (
                                  <span>{option?.value}</span>
                                )}
                                placeholder="Select Plan"
                                placement={Placement.Top}
                              />
                              <span className="regular-2 popover-arrow-color-txt ml-2">{`out of ${totalSize}`}</span>
                            </div>

                            <div className="divider" />

                            <div className="pagination-page-input d-flex align-items-center">
                              <span className="regular-2 popover-arrow-color-txt mr-2">
                                Go to page:
                              </span>
                              <div>
                                <form onSubmit={this.handleGoToPage}>
                                  <Input
                                    value={goToPage}
                                    className={goToPageInputClasses}
                                    onChange={this.handelChangeGoToPage}
                                    variant={isError && Input.Variant.Error}
                                  />
                                </form>
                              </div>
                              <span className="regular-2 popover-arrow-color-txt ml-2">{`/${Math.ceil(
                                totalSize / showRowsPerPage?.value,
                              )}`}</span>
                            </div>
                          </>
                        ) : (
                          <span className="regular-2">{`${start}-${end} of ${totalSize}`}</span>
                        )}
                        <PaginationWrapper
                          {...paginationProps}
                          isNewPagination={isNewPagination}
                          showRowsPerPage={showRowsPerPage?.value}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    );
  }
}

export default Table;

import React from 'react';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../shared/app-constants';
import { IProps } from './type';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';

const EmailAccountService = ({
  id,
  logo,
  serviceName,
  serviceDetails,
  onClick,
  isBeta = false,
  isSync = false,
}) => (
  <div
    id={id}
    className={`email-account d-flex flex-column ${id} ${isSync && 'synced'}`}
    {...accessibleOnClick(onClick)}
  >
    {isBeta && (
      <div className="ribbon rib-danger">
        <span className="rib-danger gray-txt-1">Beta</span>
      </div>
    )}
    <ImageIcon src={logo} />
    <span className="semibold-1 blue-txt-16 mt-1 email-label">
      {serviceName}
    </span>
    <span className="regular-1 service-details font-10 gray-txt-15 email-account__description">
      {serviceDetails}
    </span>
    {isSync && (
      <div className="email-account__sync">
        <ImageIcon src={Images.RefreshGreen} />
        <span className="regular-1 gray-txt-15 font-10">Sync is On</span>
      </div>
    )}
  </div>
);

const AddEmailAccountModal: React.FC<IProps> = ({
  show,
  onClose,
  onGoogleAccountSelect,
  onMicrosoftAccountSelect,
  onSMTPAccountSelect,
  onSaleshandyAccountSelect,
  t,
  isSaleshandyAccountSynced = false,
}) => (
  <>
    <Modal
      show={show}
      className="add-email-account-modal"
      titleContent="Select Account Type"
      onClose={onClose}
      showCloseIcon={true}
      hideFooter={true}
      backdrop="static"
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
      }}
    >
      <div className="email-account-message regular-2">
        {t('messages.email_account_select_message')}
      </div>

      <div className="email-account-select-service">
        <span className="semibold-2">{t('messages.select_email_service')}</span>
      </div>

      <div className="email-account-method-wrap">
        <EmailAccountService
          id="google"
          logo={Images.Google30x30}
          serviceName="Google"
          serviceDetails={t('messages.google')}
          onClick={onGoogleAccountSelect}
        />
        <EmailAccountService
          id="microsoft"
          logo={Images.Microsoft30x30}
          serviceName="Microsoft"
          serviceDetails={t('messages.microsoft')}
          onClick={onMicrosoftAccountSelect}
        />
        <EmailAccountService
          id="smtp-imap"
          logo={Images.Server30x30}
          serviceName="SMTP/IMAP"
          serviceDetails={t('messages.smtp_imap')}
          onClick={onSMTPAccountSelect}
        />
        <EmailAccountService
          id="saleshandy"
          logo={Images.SHLogoSmall}
          serviceName="Import from Saleshandy"
          serviceDetails={t('messages.saleshandy')}
          onClick={onSaleshandyAccountSelect}
          isSync={isSaleshandyAccountSynced}
        />
      </div>
      <div className="email-account-unknown-provider regular-2 mt-3">
        {t('messages.email_account_unknown_provider')}
        <a
          className="click-here"
          href="https://www.google.com"
          target="_blank"
          rel="noreferrer"
        >
          Click here
        </a>
      </div>
      <div className="email-account-note regular-2 mt-3">
        {t('messages.email_account_connect_note')}
      </div>
    </Modal>
  </>
);

export default AddEmailAccountModal;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import ConfirmationModalV3 from '../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../shared/design-system/components/atoms/confirmation-modal/enum';
import { getIsRequestPending } from '../../shared/utils/get-request-status';
import { EmailAccountMethod } from '../settings/enums/email-account';
import type { IProps } from './email-warmup-container';
import {
  connectEmailAccountHandler,
  emailWarmupToggleHandler,
  getEnableEmailWarmupConsent,
  getRestrictionPlanModalBodyContent,
  onTunrOnOffRequestSuccessHandler,
  reconnectEmailAccountHandler,
  showRequestError,
} from './helpers/helper';
import { EmailAccountWarmupList } from './types';
import { executeOnRequestStatus } from '../../shared/utils/execute-on-request-status';
import {
  redirectWithoutRefresh,
  redirectWithRefresh,
} from '../../shared/utils';
import { showToasterWithErrorCheck } from '../settings/components/email-account/utils/helpers';
import Suspense from '../../shared/components/suspense';
import Checkbox from '../../shared/design-system/components/atoms/checkbox';
import { UserSettingCode } from '../../shared/types/user-setting';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import AddEmailAccountModal from '../settings/components/email-account/components/add-email-account-modal';
import hasPermission from '../../shared/utils/access-control/has-permission';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import { SubscriptionPlans } from '../../shared/utils/subscription-plans';
import { planError } from '../../shared/utils/errors/plan-permission-error/plan-error';
import ConnectSMTPIMAPAccountModal from '../settings/components/email-account/components/connect-smtp-imap-account-modal';
import toaster, { Theme } from '../../shared/toaster';
import { SMTPIMAP } from '../settings/enums/smtp-or-imap';
import ErrorLogModal from '../settings/components/email-account/components/error-log-modal';
import RestrictionErrorModal from '../prospect/components/prospect-list/components/modals/restriction-error-modal';
import { getProspectAndEmailSentLimit } from '../home/components/header/utils/helper';
import { getIsUserSubscribed } from '../settings/components/billing-subscription/utils/helper';
import retryLazy from '../retry-lazy';
import { DatePickerOption } from '../reports/components/reports-content/components/reports-filters/components/date-range-picker/types';
import { warmupDurationOptions } from '../reports/components/reports-content/components/reports-filters/components/date-range-picker/date-range-options';
import ConnectSMTPIMAPAccountsOptionsModal from './modals/connect-smtp-imap-accounts';
import SaleshandyEmailConnectModal from './modals/saleshandy-email-account';
import ImportEmailAccountsModal from './modals/imports-email-accounts/imports-email-accounts-modal';
import { syncSaleshandyEmailAccountWithApiKey } from './helpers/email-warmup.api';
import ConnectGoogleAccountsModal from './modals/connect-google-accounts';

const EmailWarmupList = React.lazy(() =>
  retryLazy(() => import('./components/email-warmup-list')),
);
const EmailWarmupReport = React.lazy(() =>
  retryLazy(() => import('./components/email-warmup-report')),
);

const EmailWarmup: React.FC<IProps> = ({
  authUrl,
  subscriptionPlan,
  sendTurnOnOffEmailAccountWarmupRequest,
  sendConnectEmailAccountRequest,
  turnOnOffEmailAccountWarmupRequestStatus,
  turnOnOffEmailAccountWarmupRequestError,
  connectEmailAccountRequestStatus,
  connectEmailAccountRequestError,
  resetTurnOnOffEmailAccountWarmupState,
  showLoading,
  hideLoading,
  meta,
  updateEnableEmailWarmupConsent,
  sendGetUserSettingsRequest,
  updateUserSettingsRequestStatus,
  agencyConfig,
  // SMTP
  sendConnectSmtpImapResponse,
  getConnectSmtpImapRequestMessage,
  sendGetEmailAccountsRequest,
  resetSmtpImapTestConnection,
  resetSmtpIMapConnectResponse,
  resetUpdateSmtpImapAccountDetails,
  sendUpdateSmtpImapAccountDetailsResponse,
  sendUpdateEmailAccountRequestMessage,
  getTestSMTPConnectionRequestStatus,
  getTestIMAPConnectionRequestStatus,
  getConnectSmtpImapRequestStatus,
  sendUpdateSmtpImapAccountRequestStatus,
  sendGetSmtpImapAccountDetailsRequest,
  sendGetSmtpImapAccountDetailsRequestStatus,
  sendGetPostLoadMetaRequest,
  planCode,
  importEmailAccountViaCsv,
  importEmailAccountCsvRequestStatus,
  isSaleshandyAccountSynced,
  isGoogleOAuthEnabled,
}) => {
  const { t } = useTranslation();

  const [showAddEmailAccountModal, setShowAddEmailAccountModal] = useState(
    false,
  );
  const [
    showConnectAndSaveSMTPIMAPModel,
    setShowConnectAndSaveSMTPIMAPModel,
  ] = useState(false);
  const [
    showSaleshandyEmailConnectModel,
    setShowSaleshandyEmailConnectModel,
  ] = useState(false);
  const [
    showConnectGmailAccountModel,
    setShowConnectGmailAccountModel,
  ] = useState(false);
  const [isReconnectFlow, setIsReconnectFlow] = useState(false);
  const [selectGmailAsDefault, setSelectGmailAsDefault] = useState(false);
  const [errorLog, setErrorLog] = useState({
    isErrorLogModalVisible: false,
    errorLogAccountType: SMTPIMAP.SMTP,
    errorLog: '',
  });

  const [
    reconnectEmailAccountModal,
    setReconnectEmailAccountModal,
  ] = useState<boolean>(false);
  const [enableEmailWarmupModal, setEnableEmailWarmupModal] = useState<boolean>(
    false,
  );
  const [
    disconnectEmailWarmupModal,
    setDisconnectEmailWarmupModal,
  ] = useState<boolean>(false);
  const [emailAccountId, setEmailAccountId] = useState<number>(null);
  const [
    emailAccountMethod,
    setEmailAccountMethod,
  ] = useState<EmailAccountMethod>(null);
  const [senderDetails, setSenderDetails] = useState<{
    name: string;
    email: string;
  }>({ name: '', email: '' });
  const [
    isEmailWarmupEnableConsentChecked,
    setIsEmailWarmupEnableConsentChecked,
  ] = useState<boolean>(false);
  const [
    isRestrictionModalVisible,
    setIsRestrictionModalVisible,
  ] = useState<boolean>(false);
  const [dateOption, setDateOptions] = useState(warmupDurationOptions[0]);

  const [
    connectSMTPIMAPAccountsOptionsModal,
    setConnectSMTPIMAPAccountsOptionsModal,
  ] = useState(false);

  const [importEmailAccountsModal, setImportEmailAccountsModal] = useState(
    false,
  );

  const showEmailWarmupEnableConsent = getEnableEmailWarmupConsent(meta);

  //* Show Hide Restriction Error Modal
  const showRestrictionErrorModal = () => setIsRestrictionModalVisible(true);
  const hideRestrictionErrorModal = () => setIsRestrictionModalVisible(false);

  //* Show Hide Connect SMTP/IMAP Accounts Options Modal
  const showConnectSMTPIMAPAccountsOptionsModal = () =>
    setConnectSMTPIMAPAccountsOptionsModal(true);
  const hideConnectSMTPIMAPAccountsOptionsModal = () =>
    setConnectSMTPIMAPAccountsOptionsModal(false);

  // Show Hide Saleshandy Email Account Connect Modal
  const showSaleshandyEmailConnectModal = () =>
    setShowSaleshandyEmailConnectModel(true);
  const hideSaleshandyEmailConnectModal = () =>
    setShowSaleshandyEmailConnectModel(false);

  const showConnectGmailAccountModal = () =>
    setShowConnectGmailAccountModel(true);
  const hideConnectGmailAccountModal = () => {
    setIsReconnectFlow(false);
    setShowConnectGmailAccountModel(false);
  };

  //* Show Hide Import Email Accounts Modal
  const showImportEmailAccountsModal = () => setImportEmailAccountsModal(true);
  const hideImportEmailAccountsModal = () => setImportEmailAccountsModal(false);

  const [
    isSaleshandyEmailAccountLoading,
    setIsSaleshandyEmailAccountLoading,
  ] = useState(false);

  // Reconnect Email Account Modal Handlers
  const showReconnectEmailAccountModal = (
    id: number,
    method: EmailAccountMethod,
    senderDetailsObj: { name: string; email: string },
  ) => {
    setReconnectEmailAccountModal(true);
    setEmailAccountId(id);
    setEmailAccountMethod(method);
    setSenderDetails(senderDetailsObj);
  };

  const hideReconnectEmailAccountModal = () => {
    setReconnectEmailAccountModal(false);
  };

  // Disconnect Email Account Modal Handlers
  const showDisconnectEmailWarmupModal = (id: number) => {
    setDisconnectEmailWarmupModal(true);
    setEmailAccountId(id);
  };

  const hideDisconnectEmailWarmupModal = () => {
    setDisconnectEmailWarmupModal(false);
  };

  // Enable Email Account Modal Handlers
  const showEnableEmailWarmupModal = (id: number) => {
    setEnableEmailWarmupModal(true);
    setEmailAccountId(id);
  };

  const hideEnableEmailWarmupModal = () => {
    setEnableEmailWarmupModal(false);
  };

  // Disconnect Email Account
  const onDisconnectEmailWarmup = () => {
    sendTurnOnOffEmailAccountWarmupRequest({
      id: emailAccountId,
    });
  };

  // Connect Email Account
  const onConnectEmailWarmup = (id: number) => {
    connectEmailAccountHandler({
      id,
      sendTurnOnOffEmailAccountWarmupRequest,
    });
    if (showEmailWarmupEnableConsent && isEmailWarmupEnableConsentChecked) {
      updateEnableEmailWarmupConsent({
        settings: [
          {
            code: UserSettingCode.ShowEmailWarmupEnableConsent,
            value: '0',
          },
        ],
      });
    }
  };

  const showConnectAndSaveSmtpImapAccountModal = (
    method?: EmailAccountMethod,
  ) => {
    setShowConnectAndSaveSMTPIMAPModel(true);
    setShowAddEmailAccountModal(false);
    setSelectGmailAsDefault(method === EmailAccountMethod.Gmail);
  };

  // Reconnect Email Account
  const onReconnectEmailAccount = () => {
    if (emailAccountMethod === EmailAccountMethod.Gmail) {
      if (isGoogleOAuthEnabled) {
        setIsReconnectFlow(true);
        hideReconnectEmailAccountModal();
        showConnectGmailAccountModal();
        return;
      } else {
        hideReconnectEmailAccountModal();
        // showConnectAndSaveSmtpImapAccountModal();
        // sendGetSmtpImapAccountDetailsRequest(emailAccountId);
        toaster.error('Please try again after some time.', {
          theme: Theme.New,
        });
        return;
      }
    }

    reconnectEmailAccountHandler({
      subscriptionPlan,
      emailAccountId,
      emailAccountMethod,
      showConnectAndSaveSmtpImapAccountModal,
      sendConnectEmailAccountRequest,
      hideReconnectEmailAccountModal,
      sendGetSmtpImapAccountDetailsRequest,
    });
  };

  const onSaleshandyEmailAccountSync = async (shApiKey: string) => {
    // validate the api key and redirect to its saleshandy account with consent popup open
    try {
      setIsSaleshandyEmailAccountLoading(true);
      const response = await syncSaleshandyEmailAccountWithApiKey(shApiKey);
      const authUrl = response?.payload?.authUrl;
      const apiKeyVerified = response?.payload?.verified;
      if (!apiKeyVerified || !authUrl) {
        toaster.error(
          'Invalid API Key. Please check the API Key and try again.',
          {
            theme: Theme.New,
          },
        );
        return;
      }
      redirectWithRefresh(authUrl);
    } catch (error: any) {
      if (!error?.isHandled) {
        toaster.error(
          error?.message || 'Something went wrong. Please try again.',
          {
            theme: Theme.New,
          },
        );
      }
    } finally {
      setIsSaleshandyEmailAccountLoading(false);
    }
  };

  // Email Warmup Toggle
  const onEmailWarmupToggle = (check: boolean, row: EmailAccountWarmupList) => {
    const { remainingEmailWarmupEnableLimit } = getProspectAndEmailSentLimit();

    if (remainingEmailWarmupEnableLimit === 0 && check) {
      showRestrictionErrorModal();
      return;
    }

    emailWarmupToggleHandler({
      row,
      check,
      onConnectEmailWarmup,
      showReconnectEmailAccountModal,
      showDisconnectEmailWarmupModal,
      showEmailWarmupEnableConsent,
      showEnableEmailWarmupModal,
    });
  };

  const onShowAddEmailAccountModal = () => {
    setShowAddEmailAccountModal(true);
  };

  const hideAddEmailAccountModal = () => {
    setShowAddEmailAccountModal(false);
  };

  const removeParamsFromUrl = () => {
    redirectWithoutRefresh('/settings/email-accounts');
  };

  const addEmailAccountHandler = (
    method: EmailAccountMethod,
    emailAccountIdVal?: number,
  ) => {
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(5000);
      } else {
        planError(5001);
      }
    } else {
      sendConnectEmailAccountRequest(method, emailAccountIdVal);
    }
  };

  const onEmailConnectFromTableRow = (
    method: EmailAccountMethod,
    senderName: string,
    senderEmail: string,
    emailId: number,
  ) => {
    setEmailAccountId(emailId);
    setSenderDetails({
      name: senderName,
      email: senderEmail,
    });
    reconnectEmailAccountHandler({
      subscriptionPlan,
      emailAccountId: emailId,
      emailAccountMethod: method,
      showConnectAndSaveSmtpImapAccountModal,
      sendConnectEmailAccountRequest,
      hideReconnectEmailAccountModal,
      sendGetSmtpImapAccountDetailsRequest,
    });
  };

  const hideConnectAndSaveSmtpImapAccountModel = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const showAddSmtpImapAccountModal = params.get('connect-smtp');

    setShowConnectAndSaveSMTPIMAPModel(false);

    if (
      !!sendConnectSmtpImapResponse?.smtpConnection?.error ||
      !!sendConnectSmtpImapResponse?.imapConnection?.error ||
      !!sendUpdateSmtpImapAccountDetailsResponse?.smtpConnection?.error ||
      !!sendUpdateSmtpImapAccountDetailsResponse?.imapConnection?.error
    ) {
      const message =
        getConnectSmtpImapRequestMessage ||
        sendUpdateEmailAccountRequestMessage;
      toaster.success(message, { theme: Theme.New });
      sendGetEmailAccountsRequest();
    }
    resetSmtpImapTestConnection();
    resetSmtpIMapConnectResponse();
    resetUpdateSmtpImapAccountDetails();
    setEmailAccountId(null);

    if (showAddSmtpImapAccountModal) {
      removeParamsFromUrl();
    }
  };

  const showErrorLogModal = (accountType: SMTPIMAP, errorLogVal: string) => {
    setErrorLog({
      isErrorLogModalVisible: true,
      errorLogAccountType: accountType,
      errorLog: errorLogVal,
    });
  };

  const hideErrorLogModal = () => {
    setErrorLog({ ...errorLog, isErrorLogModalVisible: false });
  };

  const onEditEmailSettings = (id: number, event) => {
    event.stopPropagation();
    setEmailAccountId(id);
    sendGetSmtpImapAccountDetailsRequest(id);
  };

  const onBulkConnect = (file: File) => {
    importEmailAccountViaCsv(file);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: turnOnOffEmailAccountWarmupRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        sendGetPostLoadMetaRequest();
        setEmailAccountId(null);
        hideLoading();
        onTunrOnOffRequestSuccessHandler({
          disconnectEmailWarmupModal,
          hideDisconnectEmailWarmupModal,
          t,
        });
        resetTurnOnOffEmailAccountWarmupState();
        hideEnableEmailWarmupModal();
      },
      onFailed: () => {
        setEmailAccountId(null);
        hideLoading();
        resetTurnOnOffEmailAccountWarmupState();
        showRequestError(turnOnOffEmailAccountWarmupRequestError);
        hideEnableEmailWarmupModal();
      },
    });
  }, [turnOnOffEmailAccountWarmupRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(connectEmailAccountRequestError);
      },
    });
  }, [connectEmailAccountRequestStatus]);

  useEffect(() => {
    // Connect SMTP IMAP Account
    executeOnRequestStatus({
      status: getConnectSmtpImapRequestStatus,
      onSuccess: () => {
        if (
          sendConnectSmtpImapResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendConnectSmtpImapResponse?.imapConnection?.isConnectionEstablished
        ) {
          hideConnectAndSaveSmtpImapAccountModel();
          toaster.success(getConnectSmtpImapRequestMessage, {
            theme: Theme.New,
          });
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(getConnectSmtpImapRequestMessage);
      },
    });
  }, [getConnectSmtpImapRequestStatus]);

  useEffect(() => {
    // Update SMTP IMAP  Account Details
    executeOnRequestStatus({
      status: sendUpdateSmtpImapAccountRequestStatus,
      onSuccess: () => {
        if (
          sendUpdateSmtpImapAccountDetailsResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendUpdateSmtpImapAccountDetailsResponse?.imapConnection
            ?.isConnectionEstablished
        ) {
          toaster.success(sendUpdateEmailAccountRequestMessage, {
            theme: Theme.New,
          });
          hideConnectAndSaveSmtpImapAccountModel();
          resetUpdateSmtpImapAccountDetails();
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(sendUpdateEmailAccountRequestMessage);
      },
    });
  }, [sendUpdateSmtpImapAccountRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateUserSettingsRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
    });
  }, [updateUserSettingsRequestStatus]);

  useEffect(() => {
    // Get SMTP IMAP  Account Details
    executeOnRequestStatus({
      status: sendGetSmtpImapAccountDetailsRequestStatus,
      onSuccess: () => {
        showConnectAndSaveSmtpImapAccountModal();
      },
      onFailed: () => {
        showToasterWithErrorCheck(t('messages.email_account_not_found'));
      },
    });
  }, [sendGetSmtpImapAccountDetailsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: importEmailAccountCsvRequestStatus,
      onSuccess: () => {
        hideImportEmailAccountsModal();
      },
    });
  }, [importEmailAccountCsvRequestStatus]);

  const isConnectEmailAccountRequestPending = getIsRequestPending(
    connectEmailAccountRequestStatus,
  );

  return (
    <Container fluid className="warmup-container">
      <Row>
        <Col className="p-0">
          <ErrorBoundaryWrapper>
            <Suspense>
              <Switch>
                <Route
                  exact
                  path="/warmup/:id/report"
                  render={(props) => (
                    <EmailWarmupReport
                      {...props}
                      onEmailWarmupToggle={onEmailWarmupToggle}
                      dateOption={dateOption}
                      onSelectChange={(option: DatePickerOption) =>
                        setDateOptions(option)
                      }
                    />
                  )}
                />
                <Route
                  exact
                  path="/warmup"
                  render={(props) => (
                    <EmailWarmupList
                      {...props}
                      onEmailWarmupToggle={onEmailWarmupToggle}
                      onShowAddEmailAccountModal={onShowAddEmailAccountModal}
                      onEmailConnectFromTableRow={onEmailConnectFromTableRow}
                      onEditEmailSettings={onEditEmailSettings}
                      dateOption={dateOption}
                      showUpgradePlanModal={showRestrictionErrorModal}
                    />
                  )}
                />
                <Redirect to="/warmup" />
              </Switch>
            </Suspense>
          </ErrorBoundaryWrapper>
        </Col>
      </Row>

      <AddEmailAccountModal
        show={showAddEmailAccountModal}
        onClose={hideAddEmailAccountModal}
        isRequestPending={isConnectEmailAccountRequestPending}
        t={t}
        onGoogleAccountSelect={() => {
          if (isGoogleOAuthEnabled) {
            hideAddEmailAccountModal();
            showConnectGmailAccountModal();
          } else {
            showConnectAndSaveSmtpImapAccountModal(EmailAccountMethod.Gmail);
          }
        }}
        onMicrosoftAccountSelect={() => {
          addEmailAccountHandler(EmailAccountMethod.Microsoft);
        }}
        onSMTPAccountSelect={() => {
          hideAddEmailAccountModal();
          showConnectSMTPIMAPAccountsOptionsModal();
        }}
        onSaleshandyAccountSelect={() => {
          if (isSaleshandyAccountSynced) {
            toaster.success(
              'Email account syncing with Saleshandy is enabled',
              {
                theme: Theme.New,
              },
            );
            return;
          }
          hideAddEmailAccountModal();
          showSaleshandyEmailConnectModal();
        }}
        isSaleshandyAccountSynced={isSaleshandyAccountSynced}
      />

      <ConnectSMTPIMAPAccountsOptionsModal
        show={connectSMTPIMAPAccountsOptionsModal}
        onClose={hideConnectSMTPIMAPAccountsOptionsModal}
        onBack={() => {
          hideConnectSMTPIMAPAccountsOptionsModal();
          onShowAddEmailAccountModal();
        }}
        onSMTPAccountSelect={showConnectAndSaveSmtpImapAccountModal}
        showImportEmailAccountsModal={() => {
          hideConnectSMTPIMAPAccountsOptionsModal();
          showImportEmailAccountsModal();
        }}
      />

      {showSaleshandyEmailConnectModel && (
        <SaleshandyEmailConnectModal
          show={showSaleshandyEmailConnectModel}
          onClose={hideSaleshandyEmailConnectModal}
          onApply={onSaleshandyEmailAccountSync}
          isLoading={isSaleshandyEmailAccountLoading}
        />
      )}

      {showConnectGmailAccountModel && (
        <ConnectGoogleAccountsModal
          show={showConnectGmailAccountModel}
          onClose={hideConnectGmailAccountModal}
          onBack={() => {
            hideConnectGmailAccountModal();
            onShowAddEmailAccountModal();
          }}
          onSubmit={() => {
            if (isReconnectFlow) {
              // Reconnect flow
              sendConnectEmailAccountRequest(
                EmailAccountMethod.Gmail,
                emailAccountId,
              );
              return;
            } else {
              // Add new account flow
              addEmailAccountHandler(EmailAccountMethod.Gmail);
            }
          }}
          isSubmitLoading={isConnectEmailAccountRequestPending}
          isSubmitDisabled={isConnectEmailAccountRequestPending}
        />
      )}

      <ImportEmailAccountsModal
        show={importEmailAccountsModal}
        onClose={hideImportEmailAccountsModal}
        onBulkConnect={onBulkConnect}
        importEmailAccountCsvRequestStatus={importEmailAccountCsvRequestStatus}
      />

      {showConnectAndSaveSMTPIMAPModel && (
        <ConnectSMTPIMAPAccountModal
          show={showConnectAndSaveSMTPIMAPModel}
          onClose={hideConnectAndSaveSmtpImapAccountModel}
          isSMTPTestConnectionRequestPending={getIsRequestPending(
            getTestSMTPConnectionRequestStatus,
          )}
          isIMAPTestConnectionRequestPending={getIsRequestPending(
            getTestIMAPConnectionRequestStatus,
          )}
          isConnectAccountRequestPending={getIsRequestPending(
            getConnectSmtpImapRequestStatus,
          )}
          isUpdateSmtpImapAccountRequestPending={getIsRequestPending(
            sendUpdateSmtpImapAccountRequestStatus,
          )}
          emailAccountId={emailAccountId}
          agencyConfig={agencyConfig}
          showErrorLogModal={showErrorLogModal}
          selectGmailAsDefault={selectGmailAsDefault}
          senderDetails={senderDetails}
        />
      )}
      <ErrorLogModal
        show={errorLog.isErrorLogModalVisible}
        accountType={errorLog.errorLogAccountType}
        errorLog={errorLog.errorLog}
        onClose={hideErrorLogModal}
      />

      <ConfirmationModalV3
        show={reconnectEmailAccountModal}
        title={t('messages.reconnect_email_account_title')}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        content={t('messages.reconnect_email_account_content')}
        onClose={hideReconnectEmailAccountModal}
        onSubmit={onReconnectEmailAccount}
        isSubmitLoading={getIsRequestPending(connectEmailAccountRequestStatus)}
        isSubmitDisabled={getIsRequestPending(connectEmailAccountRequestStatus)}
        submitButtonText={t('labels.connect_now')}
        cancelButtonText={t('labels.cancel')}
        showCloseIcon={false}
      />

      <ConfirmationModalV3
        show={disconnectEmailWarmupModal}
        title={t('messages.disconnect_email_account_title')}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        content={t('messages.disconnect_email_account_content')}
        onClose={hideDisconnectEmailWarmupModal}
        onSubmit={onDisconnectEmailWarmup}
        isSubmitLoading={getIsRequestPending(
          turnOnOffEmailAccountWarmupRequestStatus,
        )}
        isSubmitDisabled={getIsRequestPending(
          turnOnOffEmailAccountWarmupRequestStatus,
        )}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
        showCloseIcon={false}
      />

      <ConfirmationModalV3
        show={enableEmailWarmupModal}
        title={t('messages.enable_email_warmup_title')}
        showCloseIcon={false}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        content={`${t('messages.enable_email_warmup_desc_1')} ${t(
          'messages.enable_email_warmup_desc_2',
        )}`}
        onClose={hideEnableEmailWarmupModal}
        onSubmit={() => onConnectEmailWarmup(emailAccountId)}
        isSubmitLoading={getIsRequestPending(
          turnOnOffEmailAccountWarmupRequestStatus,
        )}
        isSubmitDisabled={getIsRequestPending(
          turnOnOffEmailAccountWarmupRequestStatus,
        )}
        submitButtonText={t('labels.enable')}
        cancelButtonText={t('labels.cancel')}
        customJSX={
          <div>
            <Checkbox
              checked={isEmailWarmupEnableConsentChecked}
              onChange={setIsEmailWarmupEnableConsentChecked}
              label={t('messages.dont_show_this_message_again')}
              className="enable-email-warmup-consent"
            />
          </div>
        }
      />

      {isRestrictionModalVisible && (
        <RestrictionErrorModal
          show={isRestrictionModalVisible}
          onClose={hideRestrictionErrorModal}
          bodyContent={getRestrictionPlanModalBodyContent(
            getIsUserSubscribed(planCode),
          )}
          modalTitle="Upgrade to enable more emails"
        />
      )}
    </Container>
  );
};

export default EmailWarmup;

import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { PerformanceStatsVariant } from '../../../shared/design-system/components/molecules/performance-stats/types';
import toaster, { Theme } from '../../../shared/toaster';
import { UserSettingCode } from '../../../shared/types/user-setting';
import { Permissions } from '../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../shared/utils/access-control/has-permission';
import { planError } from '../../../shared/utils/errors/plan-permission-error/plan-error';
import getEmailAccountMethod from '../../../shared/utils/get-email-account-method';
import {
  getIsRequestPending,
  getIsRequestSucceeded,
} from '../../../shared/utils/get-request-status';
import { SubscriptionPlans } from '../../../shared/utils/subscription-plans';
import { EmailAccountMethod } from '../../settings/enums/email-account';
import { EmailAccountWarmupSortKey, EmailWarmupStatus } from '../enums';
import {
  ApiAction,
  EmailAccountBulkActionFilters,
  EmailAccountWarmupFilters,
  EmailAccountWarmupList,
} from '../types';
import { getProspectAndEmailSentLimit } from '../../home/components/header/utils/helper';
import { durationOptionsForEmailWarmup } from './email-filters-resolver';

export const getEmailWarmupReportDeliverabilityPercentage = (
  deliverabilityReport,
) => {
  if (deliverabilityReport) {
    if (Number(deliverabilityReport.deliverabilityPercentage) === 0) {
      return '-';
    }

    return `${deliverabilityReport.deliverabilityPercentage}%`;
  }
  return '-';
};

export const getSeriesForDeliverabilityChart = (deliverabilityReport) => {
  if (deliverabilityReport) {
    const total = Number(deliverabilityReport.total);
    const inbox = Number(deliverabilityReport.inbox);
    const spam = Number(deliverabilityReport.spam);

    if (total === 0) {
      return {
        series: [0, 0],
        colors: ['#059669', '#B91C1C'],
      };
    }

    const inboxPercentage = Math.round((inbox * 100) / total);
    const spamPercentage = Math.round((spam * 100) / total);

    // 100% Inbox Ratio
    if (inboxPercentage > 0 && spamPercentage === 0) {
      return {
        series: [inboxPercentage],
        colors: ['#059669'],
      };
    }

    // 100% Spam Ratio
    if (inboxPercentage === 0 && spamPercentage > 0) {
      return {
        series: [inboxPercentage, spamPercentage],
        colors: ['#B91C1C'],
      };
    }

    // Mixed Ration
    return {
      series: [inboxPercentage, spamPercentage],
      colors: ['#059669', '#B91C1C'],
    };
  }

  return {
    series: [0, 0],
    colors: ['#059669', '#B91C1C'],
  };
};

export const emailWarmupSettingsInputHandler = (
  value,
  minValue,
  maxValue,
  setValue,
  setError,
  t,
  hasCustomError = null,
) => {
  if (!value || value < minValue) {
    setValue(value);
    setError(
      `${t('messages.zero_value_error_1')} ${minValue} ${t(
        'messages.zero_value_error_2',
      )}`,
    );
    return;
  }
  if (value > maxValue) {
    setValue(value);

    const errorMsg =
      hasCustomError || `${t('messages.max_value_error')} ${maxValue}`;
    setError(errorMsg);
    return;
  }
  setValue(value);
  setError('');
};

export const senderNameInputHandler = (value, setValue, setError, t) => {
  if (value.length < 3 || value.length > 80) {
    setValue(value);
    setError(t('messages.char_limit_error'));
    return;
  }
  setValue(value);
  setError('');
};

export const warmUpFolderInputHandler = (value, setValue, setError, t) => {
  if (value.length > 80) {
    setValue(value);
    setError(t('lenght should be less than 80 characters'));
    return;
  }
  setValue(value);
  setError('');
};

export const getIsEmailAccountWarmupSettingsSubmitDiabled = ({
  startWithEmailsPerDayError,
  increaseByEmailsEveryDayError,
  maximumEmailsToBeSentPerDayError,
  replyRateError,
  senderNameError,
  warmUpFolderError,
  isDirty,
}): boolean =>
  startWithEmailsPerDayError ||
  increaseByEmailsEveryDayError ||
  maximumEmailsToBeSentPerDayError ||
  replyRateError ||
  senderNameError ||
  warmUpFolderError ||
  isDirty;

export const getPerformanceVariantForEmailDeliverabilityStats = (
  performance: number,
): PerformanceStatsVariant => {
  if (performance > 0) {
    return PerformanceStatsVariant.INCREMENT;
  }

  if (performance < 0) {
    return PerformanceStatsVariant.DECREMENT;
  }

  return null;
};

export const getPositiveValue = (value: number): number => {
  if (value < 0) {
    return value * -1;
  }

  return value;
};

export const getIsPieChartDataEmpty = (series: number[]): boolean => {
  if (series[0] === 0 && series[1] === 0) {
    return true;
  }

  return false;
};

export const getSortByKeyFilter = (key: string): EmailAccountWarmupSortKey => {
  if (key === 'sentToday') {
    return EmailAccountWarmupSortKey.SentToday;
  }
  if (key === 'receivedToday') {
    return EmailAccountWarmupSortKey.ReceivedToday;
  }

  return EmailAccountWarmupSortKey.Deliverability;
};

export const getEmailAccountFromList = (
  id: number,
  emails: EmailAccountWarmupList[],
) => emails?.filter((email) => email.id === id)[0];

export const getEmailWarmupStatus = (
  id: number,
  emails: EmailAccountWarmupList[],
): boolean => {
  const emailAccount = getEmailAccountFromList(id, emails);

  return Number(emailAccount?.warmupStatus) === EmailWarmupStatus.Running;
};

export const connectEmailAccountHandler = ({
  id,
  sendTurnOnOffEmailAccountWarmupRequest,
}) => {
  sendTurnOnOffEmailAccountWarmupRequest({
    id,
  });
};

export const reconnectEmailAccountHandler = ({
  subscriptionPlan,
  emailAccountId,
  emailAccountMethod,
  showConnectAndSaveSmtpImapAccountModal,
  sendConnectEmailAccountRequest,
  hideReconnectEmailAccountModal,
  sendGetSmtpImapAccountDetailsRequest,
}) => {
  if (subscriptionPlan === SubscriptionPlans.FREE) {
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      planError(5000);
    } else {
      planError(5001);
    }
  } else if (
    emailAccountMethod === EmailAccountMethod.Gmail ||
    emailAccountMethod === EmailAccountMethod.SmtpImap
  ) {
    sendGetSmtpImapAccountDetailsRequest(emailAccountId);
    showConnectAndSaveSmtpImapAccountModal(emailAccountMethod);
    hideReconnectEmailAccountModal();
  } else {
    sendConnectEmailAccountRequest(emailAccountMethod, emailAccountId);
    hideReconnectEmailAccountModal();
  }
};

export const onTunrOnOffRequestSuccessHandler = ({
  disconnectEmailWarmupModal,
  hideDisconnectEmailWarmupModal,
  t,
}) => {
  if (disconnectEmailWarmupModal) {
    hideDisconnectEmailWarmupModal();
  }
  toaster.success(
    disconnectEmailWarmupModal
      ? t('messages.email_warmup_paused_successfully')
      : t('messages.email_warmup_enabled_successfully'),
    {
      theme: Theme.New,
    },
  );
};

export const showRequestError = (error) => {
  if (error) {
    toaster.error(error.message, {
      theme: Theme.New,
      delay: 11000,
      showCloseIcon: true,
    });
  }
};

export const getShouldShowNoResult = ({
  isLoading,
  formattedData,
  filters,
}): boolean =>
  !isLoading &&
  formattedData.length === 0 &&
  (filters.search !== '' ||
    filters.tags?.length > 0 ||
    filters.status?.length > 0 ||
    filters.warmupStartDateFrom !== '' ||
    filters.warmupStartDateTo !== '');

export const getShouldHideTable = ({
  isLoading,
  formattedData,
  filters,
}): boolean =>
  !isLoading &&
  formattedData.length === 0 &&
  filters.search === '' &&
  !filters.tags?.length &&
  !filters.status?.length &&
  !filters.warmupStartDateFrom &&
  !filters.warmupStartDateTo;

export const isInitialAndCurrentFiltersSame = (initialFilters, filters) =>
  JSON.stringify(initialFilters) === JSON.stringify(filters);

export const getIsInitialRequestPending = ({
  getEmailAccountsInWarmupRequestStatus,
  isFilterDirty,
}): boolean =>
  getIsRequestPending(getEmailAccountsInWarmupRequestStatus) &&
  !isFilterDirty.current;

export const emailWarmupToggleHandler = ({
  row,
  check,
  onConnectEmailWarmup,
  showReconnectEmailAccountModal,
  showDisconnectEmailWarmupModal,
  showEmailWarmupEnableConsent,
  showEnableEmailWarmupModal,
}) => {
  if (Number(row.warmupStatus) === EmailWarmupStatus.Suspended) {
    toaster.error(
      'This email is suspended from the warm-up pool due to high bounce rate. Contact support.',
      { theme: Theme.New },
    );
  } else if (!row.isToggled && check && !row.status) {
    // * Email warm-up status is false
    // * User trying to turn it on
    // * But user email is disconnected in SH Account
    // * So showing user a modal to reconnect email account
    showReconnectEmailAccountModal(row.id, getEmailAccountMethod(row), {
      name: row.fromName,
      email: row.fromEmail,
    });
  } else if (!row.isToggled && check) {
    // * Email warm-up status is false
    // * User trying to turn it on
    // * And user email is connected in SH Account
    // * So checking for Email Warmup Enable Consent
    if (showEmailWarmupEnableConsent) {
      // * Email Warmup Enable Consent is true
      // * So making api call to enable email warmup
      showEnableEmailWarmupModal(row.id);
    } else {
      // * Email Warmup Enable Consent is false
      // * So showing user a modal to before enabling the email warmup
      onConnectEmailWarmup(row.id);
    }
  } else if (row.isToggled && !check) {
    // * Email warm-up status is true
    // * User trying to turn it off
    // * So showing user a confirmation modal before disabling email warmup
    showDisconnectEmailWarmupModal(row.id);
  }
};

export const getRecommendedLabelText = (value, t): string =>
  `${t('messages.recommended')} ${value}`;

export const getTooltipText = (): string =>
  'Upgrade your plan to access advanced warm-up setting';

export const setEmailWarmupSettingsInputValue = ({
  getEmailAccountWarmupSettingsRequestStatus,
  emailAccountWarmupSettings,
  setStartWithEmailsPerDay,
  setIncreaseByEmailsEveryDay,
  setMaximumEmailsToBeSentPerDay,
  setReplyRate,
  setSenderName,
  setWarmUpFolder,
}) => {
  if (
    getIsRequestSucceeded(getEmailAccountWarmupSettingsRequestStatus) &&
    emailAccountWarmupSettings
  ) {
    setStartWithEmailsPerDay(
      emailAccountWarmupSettings.warmUpInitialSendingLimit,
    );
    setIncreaseByEmailsEveryDay(
      emailAccountWarmupSettings.increaseEmailsByNumber,
    );
    setMaximumEmailsToBeSentPerDay(emailAccountWarmupSettings.maxSendingLimit);
    setReplyRate(emailAccountWarmupSettings.replyRate);
    setSenderName(emailAccountWarmupSettings.senderName);
    setWarmUpFolder(
      emailAccountWarmupSettings.warmUpFolder
        ? emailAccountWarmupSettings.warmUpFolder
        : '',
    );
  }
};

export const resetEmailWarmupSettingsInputValue = ({
  setStartWithEmailsPerDayError,
  setIncreaseByEmailsEveryDayError,
  setMaximumEmailsToBeSentPerDayError,
  setReplyRateError,
  setSenderNameError,
  setWarmUpFolderError,
}) => {
  setStartWithEmailsPerDayError('');
  setIncreaseByEmailsEveryDayError('');
  setMaximumEmailsToBeSentPerDayError('');
  setReplyRateError('');
  setSenderNameError('');
  setWarmUpFolderError('');
};

export const getDataFieldForTable = (sortByKey: EmailAccountWarmupSortKey) => {
  if (sortByKey === EmailAccountWarmupSortKey.Deliverability) {
    return 'deliverabilityRate';
  }
  if (sortByKey === EmailAccountWarmupSortKey.SentToday) {
    return 'sentToday';
  }
  if (sortByKey === EmailAccountWarmupSortKey.ReceivedToday) {
    return 'receivedToday';
  }
  return '';
};

export const getEnableEmailWarmupConsent = (meta) => {
  if (!isEmpty(meta)) {
    const enableEmailWarmupConsentValue =
      _.find(meta, { code: UserSettingCode.ShowEmailWarmupEnableConsent })
        ?.value || 1;

    return Boolean(Number(enableEmailWarmupConsentValue));
  }

  return true;
};

export const getRestrictionPlanModalBodyContent = (
  isUserSubscribed: boolean,
) => {
  const { totalEmailWarmupEnableLimit } = getProspectAndEmailSentLimit();

  const msg1 = `Your Current plan allows you to enable ${totalEmailWarmupEnableLimit} email accounts for Warm-up. Please upgrade your plan to warm-up more email accounts.`;
  const msg2 =
    'You are currently on Free Plan. Please upgrade your account to Warm-up Email Accounts';

  return [isUserSubscribed ? msg1 : msg2];
};

export const getFormattedWarmupStats = (value: string) => {
  if (!value) return '0';
  if (Number(value) >= 1000) {
    const flooredValue = Math.floor(Number(value) / 100) / 10;
    return flooredValue + 'k';
  }
  return value;
};

export const getEmailAccountBulkActionFilters = (
  filters: EmailAccountWarmupFilters,
): EmailAccountBulkActionFilters => {
  return {
    ...(filters?.search ? { search: filters.search } : {}),
    ...(filters?.status ? { status: filters.status } : {}),
    ...(filters?.tags ? { tags: filters.tags } : {}),
    ...(filters?.warmupStartDateFrom
      ? { warmupStartDateFrom: filters.warmupStartDateFrom }
      : {}),
    ...(filters?.warmupStartDateTo
      ? { warmupStartDateTo: filters.warmupStartDateTo }
      : {}),
  };
};

export const getBulkConfirmationModalTitleTxt = (apiAction: ApiAction) => {
  if (apiAction === ApiAction.Delete) {
    return 'Do you want to delete these email accounts?';
  }

  return `Do you want to turn ${
    apiAction === ApiAction.Disable ? 'off' : 'on'
  } email warm-up for these email accounts?`;
};

export const getBulkConfirmationModalBodyTxt = (apiAction: ApiAction) => {
  if (apiAction === ApiAction.Delete) {
    return 'If warm-up is enabled for these accounts, then it would be disabled and removed from the email account pool. Instead of deleting, you can disconnect your email accounts.';
  }

  return `Email warm-up lets you build & maintain an optimal sender reputation. ${
    apiAction === ApiAction.Disable
      ? 'Removing it may impact deliverability in the future.'
      : 'Enable it to improve deliverability in the future.'
  }`;
};

export const getDurationObject = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return null;
  }

  const from = moment.tz(startDate, 'UTC');
  const to = moment.tz(endDate, 'UTC');

  // Calculate difference in days
  const gapInDays = to.diff(from, 'days');

  // Find the matching duration option
  return (
    durationOptionsForEmailWarmup.find(
      (option) => gapInDays <= option.days,
    ) || {
      displayText: 'Unknown',
      value: null,
      key: 'Unknown',
    }
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  emailAccountTagsAssign,
  emailAccountTagsBulkAssign,
  emailAccountTagsBulkUnassign,
  emailAccountTagsUnassign,
  getEmailAccountsInWarmup,
  getEmailAccountTags,
  getEmailAccountWarmupDeliverabilityReport,
  getEmailAccountWarmupList,
  getEmailAccountWarmupSentReceivedReport,
  getEmailAccountWarmupSettings,
  getTagsInEmailWarmup,
  importEmailAccountCsv,
  saveEmailAccountWarmupSettings,
  syncSaleshandyEmailAccountWithApiKey,
  turnOnOffEmailAccountWarmup,
  saveBulkEmailAccountWarmupSettings,
  updateAllEmailAccountWarmupSettings,
  enableDisableDeleteSelectedEmailAccountWarmup,
  enableDisableDeleteBulkEmailAccountWarmup,
  getEmailAccountsBulkActionStatus,
} from './helpers/email-warmup.api';
import {
  ApiAction,
  BulkAllEmailAccountWarmupSettingsPayload,
  BulkEmailAccountWarmupSettingsPayload,
  EmailAccountBulkActionFilters,
  EmailAccountId,
  EmailAccountTagsAssignPayload,
  EmailAccountTagsBulkAssignPayload,
  EmailAccountTagsBulkUnassignPayload,
  EmailAccountTagsUnassignPayload,
  EmailAccountWarmupFilters,
  EmailAccountWarmupSettingsPayload,
  EmailWarmupDeliverabilityPayload,
} from './types';

export const getEmailAccountsInWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountWarmupFilters,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/getEmailAccountsInWarmup',
  async (args: EmailAccountWarmupFilters, thunkAPI) => {
    try {
      return await getEmailAccountsInWarmup(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountWarmupDeliverabilityReportRequest = createAsyncThunk<
  ResponseSuccess,
  EmailWarmupDeliverabilityPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/getEmailAccountWarmupDeliverabilityReport',
  async (args, thunkAPI) => {
    try {
      return await getEmailAccountWarmupDeliverabilityReport(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountWarmupListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountWarmupList', async (_args, thunkAPI) => {
  try {
    return await getEmailAccountWarmupList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEmailAccountWarmupSentReceivedReportRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountId,
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/getEmailAccountWarmupSentReceivedReport',
  async (args, thunkAPI) => {
    try {
      return await getEmailAccountWarmupSentReceivedReport(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountWarmupSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountId,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountWarmupSettings', async (args, thunkAPI) => {
  try {
    return await getEmailAccountWarmupSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const saveEmailAccountWarmupSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountWarmupSettingsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/saveEmailAccountWarmupSettings', async (args, thunkAPI) => {
  try {
    return await saveEmailAccountWarmupSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const saveBulkEmailAccountWarmupSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkEmailAccountWarmupSettingsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/saveBulkEmailAccountWarmupSettings', async (args, thunkAPI) => {
  try {
    return await saveBulkEmailAccountWarmupSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateAllEmailAccountWarmupSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkAllEmailAccountWarmupSettingsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/updateAllEmailAccountWarmupSettings', async (args, thunkAPI) => {
  try {
    return await updateAllEmailAccountWarmupSettings(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const turnOnOffEmailAccountWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountId,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/turnOnOffEmailAccountWarmup', async (args, thunkAPI) => {
  try {
    return await turnOnOffEmailAccountWarmup(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const importEmailAccountCsvRequest = createAsyncThunk<
  ResponseSuccess,
  { file: File },
  { rejectValue: ResponseErrorWithHandled }
>('emailAccount/import', async ({ file }, thunkAPI) => {
  try {
    return await importEmailAccountCsv(file);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const syncSaleshandyEmailAccountWithApiKeyRequest = createAsyncThunk<
  ResponseSuccess,
  { apiKey: string },
  { rejectValue: ResponseErrorWithHandled }
>('saleshandyEmailAccountWithApiKey', async ({ apiKey }, thunkAPI) => {
  try {
    return await syncSaleshandyEmailAccountWithApiKey(apiKey);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
export const getTagsEmailWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('email-account/getTagsRequest', async (_, thunkAPI) => {
  try {
    return await getTagsInEmailWarmup();
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const getEmailAccountsTagsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountsTags', async (_, thunkAPI) => {
  try {
    return await getEmailAccountTags();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const emailAccountTagsAssignRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountTagsAssignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/emailAccountTagsAssign', async (args, thunkAPI) => {
  try {
    return await emailAccountTagsAssign(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const emailAccountTagsBulkAssignRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountTagsBulkAssignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/emailAccountTagsBulkAssign', async (args, thunkAPI) => {
  try {
    return await emailAccountTagsBulkAssign(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const emailAccountTagsUnassignRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountTagsUnassignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/emailAccountTagsUnassign', async (args, thunkAPI) => {
  try {
    return await emailAccountTagsUnassign(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const emailAccountTagsBulkUnassignRequest = createAsyncThunk<
  ResponseSuccess,
  EmailAccountTagsBulkUnassignPayload,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/emailAccountTagsBulkUnassign', async (args, thunkAPI) => {
  try {
    return await emailAccountTagsBulkUnassign(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const enableDisableDeleteSelectedEmailAccountWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  { apiAction: ApiAction; emailAccountIds: number[] },
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/enableDisableSelectedEmailAccountWarmup',
  async ({ apiAction, emailAccountIds }, thunkAPI) => {
    try {
      return await enableDisableDeleteSelectedEmailAccountWarmup(
        apiAction,
        emailAccountIds,
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const enableDisableDeleteBulkEmailAccountWarmupRequest = createAsyncThunk<
  ResponseSuccess,
  {
    apiAction: ApiAction;
    deSelectedEmailAccountIds: number[];
    emailFilters: EmailAccountBulkActionFilters;
  },
  { rejectValue: ResponseErrorWithHandled }
>(
  'emailWarmup/enableDisableDeleteBulkEmailAccountWarmup',
  async ({ apiAction, deSelectedEmailAccountIds, emailFilters }, thunkAPI) => {
    try {
      return await enableDisableDeleteBulkEmailAccountWarmup(
        apiAction,
        deSelectedEmailAccountIds,
        emailFilters,
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getEmailAccountsBulkActionStatusRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('emailWarmup/getEmailAccountsBulkActionStatus', async (_, thunkAPI) => {
  try {
    const response = await getEmailAccountsBulkActionStatus();
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
